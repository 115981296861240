import DashboardWrapper from './DashboardWrapper'
import { useRef, useState } from 'react'
import Button from '../Button'
import {  updateEvent, uploadEventFile } from '../../firestore'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import RundownCard from './partials/RundownCard'
import CreateNew from './partials/CreateNew'
import { IMAGE_TYPES, MAX_FILE_SIZE } from '../../constants/fileTypes'
import PropTypes from 'prop-types'
import { updateEventAtom } from '../../store/eventList.store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch, faLink } from '@fortawesome/free-solid-svg-icons'
import EventCollaborateModal from '../modal/EventCollaborateModal'

export default function Event () {
  const {
    eventList,
    rundownList,
  } = useOutletContext()
  const { teamId, eventId } = useParams()
  const [updatingName, setUpdatingName] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const navigate = useNavigate()
  const setUpdateEvent = useSetAtom(updateEventAtom)

  const eventNameInputRef = useRef()

  const event = eventList.filter((event) => event.id === eventId)[0]
  const [eventName, setEventName] = useState(event?.name || '')
  const rundowns = rundownList.filter((rundown) => rundown.eventId === eventId && !rundown.archivedAt)

  const handleUpdateEvent = async (updateType, update) => {
    const { data } = await updateEvent(event.id, {[updateType]: update})
    setUpdateEvent(data)
    return data
  }

  const handleUpdateEventName = async () => {
    if(eventName === '' || eventName === event.name) return
    setUpdatingName(true)
    await handleUpdateEvent('name', eventName)
    setUpdatingName(false)
  }

  const handleOnKeyDown = (e) => {
    if(e.key === 'Enter') {
      handleUpdateEventName()
      eventNameInputRef.current.blur()
    } else if (e.key === 'Escape') {
      setEventName(event.name)
      eventNameInputRef.current.blur()
    }
  }

  return (
    <DashboardWrapper
      title={
        <div className='flex flex-col sm:flex-row gap-2 items-center sm:items-start'>
          <Link to={`/dashboard/${teamId}`} className='text-gray-400 hover:text-gray-100 transition-colors'>Dashboard</Link>
          <span className='text-gray-400 hidden sm:inline-block'>→</span>
          <input
            ref={eventNameInputRef}
            value={eventName}
            className={[
              'relative w-full hover:underline focus:hover:!no-underline focus:outline-none focus:ring rounded text-center sm:text-left',
              updatingName ? 'bg-animated from-gray-800 to-gray-600' : 'bg-transparent',
            ].join(' ')}
            onChange={(e) => setEventName(e.target.value)}
            onClick={(e) => e.target.select()}
            onBlur={handleUpdateEventName}
            onKeyDown={handleOnKeyDown}
          />
          <EventLogo eventId={eventId} logo={event?.logo} handleUpdateEvent={handleUpdateEvent}/>
          <Button
            text={<p>Invite Guests <FontAwesomeIcon icon={faLink} /></p>}
            className='text-sm mx-2'
            onClick={() => setShareModalOpen(true)}
            colour='dark'
            toolTip={'Share all rundowns on ' + event?.name}
          />
        </div>
      }
    >
      <div className='mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
        <CreateNew text='Create new rundown' handleOnCreate={() => navigate(`/create?event=${eventId}`)} />
        {rundowns.map((rundown)=>{
          return (
            <RundownCard key={rundown.id} rundown={rundown} />
          )
        })}
      </div>
      <EventCollaborateModal
        eventId={eventId}
        open={shareModalOpen}
        setOpen={setShareModalOpen}
      />
    </DashboardWrapper>
  )
}

const EventLogo = ({ eventId, logo, handleUpdateEvent }) => {
  const [loading, setLoading] = useState(false)

  const eventLogoInputRef = useRef()

  async function handleAddEventLogo (e) {
    const files = e.target.files

    if (files.length > 1) return window.alert('Cannot add more than one file at a time.')
    const file = files[0]

    if (!IMAGE_TYPES.includes(file.type)) return window.alert('File type not supported.')
    if (file.size > MAX_FILE_SIZE) return window.alert('Filesize must be 10MB or less.')

    try {
      setLoading(true)
      const { data } = await uploadEventFile(eventId, file)
      console.log('[handleFileDrop]', data)
      handleUpdateEvent('logo', data.url)
    } catch (e) {
      console.error('[file upload]', e)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="flex items-center gap-2 h-9 rounded border border-white/20 px-3 text-sm">
        <span>Uploading</span>
        <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" fixedWidth />
      </div>
    )
  }

  return (
    <div className='relative group'>
      {logo && <img src={logo} className='h-12 object-contain max-w-[14rem]' />}
      <div className={[
        logo ? 'absolute opacity-0 group-hover:opacity-100 top-1 right-1 transition-opacity' : 'opacity-100',
      ].join(' ')}>
        <Button
          onClick={() => eventLogoInputRef.current.click()} text={logo ? 'Change' : 'Add logo'}
          className='text-sm'
          colour='dark'
          toolTip={logo ? '' : 'Appears on all rundowns within this event'}

        />
      </div>
      <input ref={eventLogoInputRef} type='file' className='hidden' accept={IMAGE_TYPES} onChange={handleAddEventLogo} />
    </div>)
}

EventLogo.propTypes = {
  eventId: PropTypes.string.isRequired,
  handleUpdateEvent: PropTypes.func.isRequired,
  logo: PropTypes.string,
}
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { formatTimeOfDay } from '../../utils/formatTime.js'
import formatTimezone from '../../utils/formatTimezone.js'

export default function CurrentTimeOfDay ({ timezone, omitMinutes = false, showTimezone = false }) {
  const [t, setT] = useState(new Date())

  // Update the time 't' every 500 ms (and don't cause infinite update loops)
  useEffect(() => {
    const timeoutId = setInterval(() => setT(new Date()), 500)
    return () => clearInterval(timeoutId)
  }, [])

  const formattedTod = formatTimeOfDay(t, { timezone, omitMinutes })

  if (timezone && showTimezone) return `${formattedTod} (${formatTimezone(timezone, 'abbr')})`
  return formattedTod
}

CurrentTimeOfDay.propTypes = {
  timezone: PropTypes.string,
  omitMinutes: PropTypes.bool,
  showTimezone: PropTypes.bool,
}

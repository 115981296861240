import ContextMenu from '../ContextMenu.jsx'
import TimeInput from './TimeInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import PropTypes from 'prop-types'
import formatTimezone from '../../utils/formatTimezone.js'
import eventBus from '../../utils/eventBus.js'

export default function CueItemStart ({
  className,
  timezone,
  time,
  updateTime,
  handleCollapseAllGroups,
  handleExpandAllGroups,
  ...props
}) {
  const [showCollapseContextMenu, setShowCollapseContextMenu] = useState(false)

  const handleItemClick = async (item, secondary) => {
    setShowCollapseContextMenu(false)
    switch (item) {
      case 'collapse_all':
        return handleCollapseAllGroups()
      case 'expand_all':
        return handleExpandAllGroups()

      default:
        return console.info(item, secondary)
    }
  }

  function getFormattedTimezone () {
    if (!timezone) return 'Choose a timezone...'
    if (timezone === 'UTC') return formatTimezone(timezone, 'abbr')
    return `${formatTimezone(timezone, 'city')} (${formatTimezone(timezone, 'abbr')})`
  }

  return (
    <div
      className={['flex items-baseline justify-start gap-1', className].join(' ')}
      {...props}
    >
      {/* Gear Button */}
      <button
        className={[
          'flex-none w-7 h-7 rounded opacity-40 hover:!opacity-100 transition-opacity focus:outline-none focus:ring',
        ].join(' ')}
        onClick={(event) => setShowCollapseContextMenu(event.nativeEvent)}
      >
        <FontAwesomeIcon icon={faCog} size='sm' />
      </button>

      {/* Time Label */}
      <div className="flex-none px-2 uppercase font-semibold text-right text-gray-300">
        Start
      </div>

      {/* Time Display */}
      <div className="flex-none">
        <TimeInput
          className='h-6 px-2'
          timezone={timezone}
          time={time}
          updateTime={({ time }) => updateTime(time)}
          style={{ width: 'auto' }}
        />
      </div>

      {/* Timezone */}
      <div className="">
        <button
          className="transition-colors px-2 h-6 text-gray-300 rounded-sm hover:bg-white/20"
          onClick={() => eventBus.$emit('openTimezoneModal')}
          title={timezone ? `${formatTimezone(timezone, 'long')}, ${formatTimezone(timezone, 'offset')}` : undefined}
        >
          {getFormattedTimezone()}
        </button>
      </div>

      {showCollapseContextMenu && (
        <ContextMenu
          openEvent={showCollapseContextMenu}
          onClose={() => setShowCollapseContextMenu(null)}
          onClick={handleItemClick}
          items={[
            {
              name: 'Collapse all groups',
              value: 'collapse_all',
            },
            {
              name: 'Expand all groups',
              value: 'expand_all',
            },
          ]}
        />
      )}
    </div>
  )
}

CueItemStart.propTypes = {
  className: PropTypes.string,
  timezone: PropTypes.string,
  time: PropTypes.instanceOf(Date).isRequired,
  updateTime: PropTypes.func.isRequired,
  handleCollapseAllGroups: PropTypes.func.isRequired,
  handleExpandAllGroups: PropTypes.func.isRequired,
}

import { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getUserTeams } from '../firebase'
import Select from '../components/Select'
import UpgradeMessage from '../components/UpgradeMessage'

export default function CheckoutPre() {
  const [searchParams] = useSearchParams()
  const [team, setTeam] = useState(null)
  const [teams, setTeams] = useState([])
  const navigate = useNavigate()
  const planId = searchParams.get('plan_id')
  const discountCode = searchParams.get('discount_code')
  const discountId = searchParams.get('discount_id')
  const additionalTeamMembersId = searchParams.get('additional_members_id')
  const additionalTeamMembersCount = searchParams.get('additional_members_count')

  useEffect(() => {
    if(!team) return
    const query = new URLSearchParams({ 'plan_id': planId, 'team_id': team })
    if (discountCode) query.set('discount_code', discountCode)
    else if (discountId) query.set('discount_id', discountId)
    if (additionalTeamMembersId) query.set('additional_members_id', additionalTeamMembersId)
    if (additionalTeamMembersCount) query.set('additional_members_count', additionalTeamMembersCount)
    if (planId) navigate(`/checkout?${query}`, { replace: true })
  }, [team])

  const checkUserAndTeam = async () => {
    const awaitedUserTeams = await getUserTeams()
    setTeams(awaitedUserTeams)
    if(awaitedUserTeams.length === 1) setTeam(awaitedUserTeams[0].id)
  }

  useEffect(() => {
    checkUserAndTeam()
  }, [])

  return (
    <Layout title="Checkout">
      {!planId ?
        <UpgradeMessage message='Missing a product.' />
        : <Select
          label="Team"
          value={team}
          required={teams?.length > 0}
          options={[
            {
              name: 'Choose a team...',
              value: '',
            },
            ...teams.map((team) => ({
              name: team.name,
              value: team.id,
            })),
          ]}
          onChange={(teamId) => {
            setTeam(teamId)
          }}
          loading={!teams.length}
        />}

    </Layout>
  )
}

import PropTypes from 'prop-types'
import { useCurrentEditor } from '@tiptap/react'
import { useEffect, useRef } from 'react'


/**
 * Component that updates the TipTap editor content when the `content` prop changes.
 * This is useful when the content needs to be updated from an external source.
 *
 * @param {object} props - Component props.
 * @param {string} props.content - The content to update the editor with.
 * @returns {null} This component does not render anything.
 */
export default function TipTapContentUpdater ({ content }) {
  const { editor } = useCurrentEditor()
  const isFirstRender = useRef(true)

  // Runs on changes to `content`
  useEffect(() => {
    // Skip the effect on the first render
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    // Check if the current editor content is different from the new content.
    const editorContent = editor.getHTML()

    // If the editor content is different from the provided content, update the editor.
    if (editorContent !== content) {
      setTimeout(() => editor.commands.setContent(content))
    }
  }, [editor, content])

  // This component does not render anything to the DOM.
  return null
}

TipTapContentUpdater.propTypes = {
  content: PropTypes.string,
}

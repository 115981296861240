/**
 * Takes a firestore doc and returns a doc with parsed dates
 * @param  {FsEvent} firestoreDoc
 * @return {Event}
 */
export default function parseEvent(firestoreDoc) {
  const parsedDoc = firestoreDoc.data()
  parsedDoc.id = firestoreDoc.id
  parsedDoc.startTime = parsedDoc?.startTime?.toDate?.()
  parsedDoc.endTime = parsedDoc?.endTime?.toDate?.()
  return parsedDoc
}

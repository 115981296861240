import mitt from 'mitt'

const emitter = mitt()

/**
 * A simple global event bus.
 *
 * Example usage:
 *   eventBus.$on('openTimezoneModal', handleOpenTimezone)
 *   eventBus.$off('openTimezoneModal', handleOpenTimezone)
 *   eventBus.$emit('openTimezoneModal', { optional, data })
 */
export default {
  $on: (...args) => emitter.on(...args),
  $off: (...args) => emitter.off(...args),
  $emit: (...args) => emitter.emit(...args),
}

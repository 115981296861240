import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import Layout from '../components/Layout'
import { getUser } from '../firebase'
import { acceptTeamInvite, getTeam } from '../firestore'
import { ProgressBarCss } from '../components/toast/ToastWrapper'

export default function AcceptInvite() {
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({})
  const [team, setTeam] = useState({})

  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)

  const checkUserAndTeam = async () => {
    setLoading(true)
    const awaitedUser = await getUser()
    setUser(awaitedUser)
    const { data } = await getTeam(params.get('teamId'))
    setTeam(data)
    setLoading(false)
  }

  const handleAcceptInvite = async () => {
    setLoading(true)
    const teamId = params.get('teamId')
    await acceptTeamInvite(teamId)
    localStorage.setItem('teamId', teamId)
    return navigate(`/dashboard/${teamId}`)
  }

  useEffect(() => {
    checkUserAndTeam()
  }, [])

  if (loading) return <Layout title="Loading..." loading={true} />

  return (
    <Layout title="Accept invite">
      <p>
        You've been invited to join <span className="font-bold">{team?.name}</span>.
      </p>

      {/* NOT LOGGED IN */}
      {!user?.uid && (
        <>
          <p>Log in to accept the invite.</p>
          <Button text="Login" onClick={() => navigate(`/login?next=${window.location.pathname}${window.location.search}`)} />
          <Button text="Signup" colour="dark" onClick={() => navigate(`/signup?next=${window.location.pathname}${window.location.search}`)} />
        </>
      )}

      {/* LOGGED INTO THE WRONG ACCOUNT */}
      {user?.uid && !Object.keys(team?.pending || []).includes(user?.email) && (
        <>
          <p>Looks like you are logged into the wrong account.</p>
          <Button text="Log into another account" onClick={() => navigate(`/logout?next=${window.location.pathname}${window.location.search}`)} />
        </>
      )}

      {/* READY TO ACCEPT */}
      {user?.uid && Object.keys(team?.pending || []).includes(user?.email) && (
        <>
          <p>To join the team, accept the invite below.</p>
          <Button text="Accept invite" onClick={handleAcceptInvite} />
        </>
      )}
    </Layout>
  )
}

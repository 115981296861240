import ModalWrapper from './ModalWrapper.jsx'
import ShareableLinkAndAccessCode from './partials/ShareableLinkAndAccessCode.jsx'
import UpgradeMessage from '../UpgradeMessage.jsx'
import PropTypes from 'prop-types'
import { useAtom, useAtomValue } from 'jotai'
import { useState, useEffect, useCallback } from 'react'
import { columnsAtom } from '../../store/rundown.store.js'
import { planAtom } from '../../store/plan.store.js'
import { generateRundownLinkWithSignature } from '../../utils/generateLinkWithSignature.js'
import _without from 'lodash/without'
import _omit from 'lodash/omit.js'
import { GUEST_EDIT } from '../../constants/billingTypes.js'

export default function RundownCollaborateModal({ rundownId, open, setOpen }) {
  const plan = useAtomValue(planAtom)
  const [columns] = useAtom(columnsAtom)
  const [options, setOptions] = useState({})
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState()
  const [columnsHidden, setColumnsHidden] = useState([])
  const [fullAccess, setFullAccess] = useState(0)
  /**
   * Build the shareable url from options and selectedLayout.
   * Is executed on onShow and every time the dependencies update.
   */
  const buildUrl = useCallback(async () => {
    setLoading(true)
    const newUrl = await generateRundownLinkWithSignature(rundownId, [], options)
    setUrl(newUrl)
    setLoading(false)
  }, [rundownId, options])
  useEffect(() => { if(open) buildUrl() }, [open, buildUrl])

  function handleUnCheckAll () {
    const updatedArray = [...columns.map((col) => col.id)]
    setOptions({ ...options, 'hiddenColumns': updatedArray.join(',') })
    return setColumnsHidden(updatedArray)
  }

  function handleCheckUnCheck (event) {
    const updatedArray = event.target.checked ? _without(columnsHidden, event.target.id) : [...columnsHidden, event.target.id]
    setOptions({ ...options, 'hiddenColumns': updatedArray.join(',') })
    return setColumnsHidden(updatedArray)
  }

  function handleSetEditColumn (event) {
    setOptions({ ...options, 'edit-columns': event.target.value })
  }

  function handleSetFullAccess (event) {
    const bool = event.target.checked ? 1 : 0
    let updatedOptions
    if (!bool) {
      // Remove full-access from options if it is falsy
      updatedOptions = _omit(options, 'full-access')
      // Reset any previously hidden columns
      setColumnsHidden([])
    } else {
      // Remove any previously hiddenColumns or edit-columns from the URL
      // Add full-access if it is truthy
      updatedOptions = { ..._omit(options, ['hiddenColumns', 'edit-columns']), 'full-access': bool }
    }

    setOptions(updatedOptions)
    setFullAccess(bool)
  }

  return (
    <ModalWrapper
      title="Invite Guests"
      open={[open, setOpen]}
      onShow={buildUrl}
    >
      <div className=''>
        <p className="text-gray-400 font-light">Choose if guests have full access to edit cues, columns and run the show</p>
        <label
          htmlFor={'fullAccess'}
          className={[
            'p-2 mt-2 bg-gray-800 rounded flex gap-2',
            plan.features.includes(GUEST_EDIT) ? 'cursor-pointer hover:brightness-110' : 'opacity-40',
          ].join(' ')}
        >
          <input
            id={'fullAccess'}
            type="checkbox"
            className="focus:outline-none focus:ring"
            checked={fullAccess}
            onChange={handleSetFullAccess}
            disabled={!plan.features.includes(GUEST_EDIT)}
          />
          <span>Full read and write access</span>
        </label>
        {!plan.features.includes(GUEST_EDIT) && <UpgradeMessage message='Upgrade your account to give guests edit access.' className='text-right'/>}
      </div>
      <div className="mt-4 grid grid-cols-2 gap-12">
        {/* Column show and hide */}
        <div className="space-y-2">
          <p className="text-gray-400 font-light">Choose which columns guests can see</p>
          <div className={[
            'flex flex-col gap-2 justify-center',
            fullAccess && 'opacity-50',
          ].join(' ')}>
            {columns.map((column, i) => (
              <label
                key={i}
                htmlFor={`${column?.id}`}
                className={[
                  'p-2 bg-gray-800 rounded flex gap-2',
                  fullAccess ? '' : 'cursor-pointer hover:brightness-110',
                ].join(' ')}
              >
                <input
                  id={`${column?.id}`}
                  type="checkbox"
                  className="focus:outline-none focus:ring disabled:pointer-events-none"
                  checked={!columnsHidden?.includes(column?.id)}
                  onChange={handleCheckUnCheck}
                  disabled={fullAccess}
                />
                <span>{column?.name || 'Unnamed Column'}</span>
              </label>
            ))}
          </div>
          <button
            className="text-xs text-gray-400 focus:outline-none focus:ring disabled:opacity-50"
            onClick={handleUnCheckAll}
            disabled={fullAccess}
          >
            Hide all
          </button>
        </div>

        {/* Cell editing */}
        <div className="space-y-2">
          <p className="text-gray-400 font-light">Choose which column guests can edit</p>
          <select
            className="p-2 w-full bg-gray-800 rounded flex gap-2 cursor-pointer hover:brightness-110 disabled:brightness-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring"
            disabled={!plan.features.includes(GUEST_EDIT) || fullAccess}
            onChange={handleSetEditColumn}
          >
            <option value="">None</option>
            {columns.map((col) => (
              <option key={col.id} value={col.id}>
                {col.name || 'Unnamed Column'}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-6 space-y-2">
        <p className="text-gray-400 font-light">Shareable Link</p>
        <ShareableLinkAndAccessCode
          className=""
          url={url}
          loading={loading}
        />
      </div>
    </ModalWrapper>
  )
}

RundownCollaborateModal.propTypes = {
  rundownId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

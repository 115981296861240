import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFileAudio, faFileCsv, faFileExcel, faFilePdf, faFilePowerpoint, faFileVideo, faFileWord, faFileZipper } from '@fortawesome/free-solid-svg-icons'
import { NodeViewWrapper } from '@tiptap/react'
import { CSV, DOC, DOCX, GZIP, ODP, ODS, ODT, PDF, PPT, PPTX, RAR, TAR, XLS, XLSX, ZIP, ZIP7 } from '../../constants/fileTypes'

/**
 * A Tiptap extension component for rendering files uploaded to the editor.
 * This component displays a file icon and information, and allows users to
 * download the file by clicking a button.
 *
 * Note: I tried to change the <button> into a <a> directly, but TipTap seems unable to handle drag-and-drop with anchor tags.
 */
export default function File({ node }) {

  function onClick () {
    const link = document.createElement('a')
    link.href = node.attrs.url
    link.target = '_blank' // open in a new tab
    link.rel = 'noreferrer'
    link.download = node.attrs.filename // use the filename from the response
    link.click()
  }

  return (
    <NodeViewWrapper className="my-2">
      <button
        className="block w-full !no-underline bg-white/10 hover:bg-white/20 backdrop-blur-md p-1 pl-2 rounded shadow flex items-center gap-3"
        title="Click to download"
        onClick={onClick}
        data-drag-handle
      >
        <FontAwesomeIcon
          icon={getIcon(node.attrs.type)}
          className="flex-none text-3xl text-white"
        />
        <div className="grow text-left overflow-hidden">
          <div className="text-white font-semibold truncate">{node.attrs.filename}</div>
          <div className="text-white/60 text-xs truncate">{node.attrs.size}</div>
        </div>
      </button>
    </NodeViewWrapper>
  )
}

function getIcon (filetype) {
  switch (filetype) {
    case PDF:
      return faFilePdf
    case CSV:
      return faFileCsv
    case DOC || DOCX || ODT:
      return faFileWord
    case XLS || XLSX || ODS:
      return faFileExcel
    case PPT || PPTX || ODP:
      return faFilePowerpoint
    case ZIP || ZIP7 || RAR || GZIP || TAR:
      return faFileZipper
  }
  if (filetype.startsWith('audio')) return faFileAudio
  if (filetype.startsWith('video')) return faFileVideo
  return faFile
}

File.propTypes = {
  node: PropTypes.shape({
    attrs: PropTypes.shape({
      filename: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  }),
}

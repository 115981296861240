import { useAtomValue } from 'jotai'
import { pingAtom } from '../../store/runner.store.js'
import { Tooltip, TooltipTrigger, TooltipContent } from '../interactives/Tooltip.jsx'

export default function ConnectionStatus () {
  const ping = useAtomValue(pingAtom)

  let status = getStatus(ping)
  let statusColor = {
    [-1]: 'text-white',
    [0]: 'text-red-400',
    [1]: 'text-amber-400',
    [2]: 'text-lime-400',
    [3]: 'text-green-400',
  }[status]

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className={['flex items-baseline gap-1 pt-1', statusColor].join(' ')}>
          <div className={['w-1 h-2 rounded bg-current', (status < 1 ? 'opacity-50' : '')].join(' ')}></div>
          <div className={['w-1 h-3 rounded bg-current', (status < 2 ? 'opacity-50' : '')].join(' ')}></div>
          <div className={['w-1 h-4 rounded bg-current', (status < 3 ? 'opacity-50' : '')].join(' ')}></div>
        </div>
      </TooltipTrigger>
      <TooltipContent>
        Connection ping: {ping}
      </TooltipContent>
    </Tooltip>
  )
}

function getStatus (ping) {
  if (ping <= 0) return -1
  if (ping < 350) return 3
  if (ping < 700) return 2
  if (ping < 1200) return 1
  return 0
}

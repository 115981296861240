import PropTypes from 'prop-types'
import * as cueState from '../../constants/cueStates.js'

export default function CueItemIndex ({
  className,
  index,
  state,
  readonly,
  loading,
  onSetNext,
  onJumpToCue,
}) {
  const isActive = state === cueState.CUE_STATE_ACTIVE
  const isNext = state === cueState.CUE_STATE_NEXT

  return (
    <button
      className={[
        'h-full w-full font-mono border-2 border-transparent rounded transition-color focus:outline-none focus:ring',
        (isNext && 'border-white animate-pulse'),
        (loading && 'bg-animated from-transparent to-white/20'),
        (!(readonly || isNext || isActive) && 'hover:border-white/50'),
        className,
      ].join(' ')}
      onClick={onSetNext}
      onDoubleClick={onJumpToCue}
      disabled={readonly || isActive}
    >
      <span>{index}</span>
    </button>
  )
}

CueItemIndex.propTypes = {
  className: PropTypes.string,
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  state: PropTypes.oneOf(Object.values(cueState)),
  readonly: PropTypes.bool,
  loading: PropTypes.bool,
  onSetNext: PropTypes.func,
  onJumpToCue: PropTypes.func,
}

import { redirect } from 'react-router-dom'
import { getUser } from './../firebase.js'

/**
 * Wrapper to handle multiple middleware functions in order of priority
 * @param  {...function} fns
 * @return {function}
 */
export function useMiddleware (...fns) {
  return async ({ request, params }) => {
    for (const fn of fns) {
      const r = await fn({ request, params })
      if (r === undefined || r === null) continue
      return r
    }
    return null
  }
}

/**
 * Wait for the user object to be available
 */
export async function awaitUser () {
  await getUser()
}

/**
 * Require the user to be signed in an verified
 */
export async function requireAuth () {
  const user = await getUser()
  const next = encodeURIComponent(window.location.pathname + window.location.search)
  if (!user) return redirect(`/login?next=${next}`)
  // TO DO reimplment this step
  // if (!user?.emailVerified) return redirect('/verify')
}

/**
 * If user is logged in, redirect to dashboard
 */
export async function dashboardRedirect () {
  const user = await getUser()
  if (user) return redirect('/dashboard')
}

import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { teamsByUid } from './firestore.js'
import { updateUserId } from './sentry.js'
import commitUTMParams from './utils/commitUTMParams.js'
import FIREBASE_CONFIG_DEV from '../firebase-public-config.dev.json'
import FIREBASE_CONFIG_PROD from '../firebase-public-config.prod.json'

const CONFIG_MAP = {
  'rundown-app-641e0': FIREBASE_CONFIG_DEV,
  'rundown-studio-46e96': FIREBASE_CONFIG_PROD,
}

// Initialize Firebase
export const app = initializeApp(CONFIG_MAP[import.meta.env.VITE_PROJECT_ID])
export const analytics = getAnalytics(app)
export const db = getFirestore(app)
export const auth = getAuth(app)

/**
 * Authenticate with firebase and get the user
 * You can do `await getUser()` to wait until auth is finished
 * @idempotent
 *
 * @return {Promise<FirebaseUser>}
 */
export async function getUser () {
  if (auth.currentUser) return auth.currentUser
  let resolveUser = null
  const userPromise = new Promise(r => resolveUser = r)

  // Listen to auth initialization
  onAuthStateChanged(auth, user => {
    resolveUser(user)
    updateUserId(user?.uid)
    if (user?.email) commitUTMParams()
  })

  return userPromise
}

/**
 * Get a user's teams
 * @return {Promise<Team[]>}
 */
export async function getUserTeams () {
  const user = await getUser()
  const { data: teams } = await teamsByUid(user?.uid)
  return teams
}

import { atom } from 'jotai'
import { nanoid } from 'nanoid/non-secure'
import _omit from 'lodash/omit'
import { WARN } from '../utils/toastTypes.js'

export const _toastAtom = atom({})
export const toastAtom = atom(
  (get) => get(_toastAtom),
  addToast,
)

const toastDefaults = {
  type: WARN,
  duration: 10000,
  dismissible: true,
}

/**
 * @function addToast
 * @param {Function} get - Function to get the current toast state
 * @param {Function} set - Function to set the new toast state
 * @param {Object} newToast - New toast object to add
 * @param {string} newToast.title - Main title of the toast
 * @param {string} newToast.body - Body or description of the toast
 * @param {('warn'|'fail'|'success')} newToast.type = 'warn' - The type of toast: 'warn', 'success', 'fail'
 * @param {number} newToast.duration = 10000 - Time in milliseconds before disappearing, -1 for indefinite duration
 * @returns {Object} Toast control object containing id, dismiss, and visible methods
 */
function addToast(get, set, newToast) {
  const toasts = get(_toastAtom)
  const id = nanoid()

  const dismiss = () => {
    set(_toastAtom, _omit(get(_toastAtom), id))
  }

  const visible = () => {
    const currentToasts = get(_toastAtom)
    return Boolean(currentToasts[id])
  }

  const toast = {
    id,
    ...toastDefaults,
    ...newToast,
    dismiss,
    visible,
  }
  set(_toastAtom, { ...toasts, [id]: toast })

  if (toast.duration !== -1) {
    setTimeout(() => {
      set(_toastAtom, _omit(get(_toastAtom), id))
    }, [toast.duration])
  }

  return toast
}

import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'


export default function EditableHms ({
  hms,
  onUpdate,
  onKeyDown = () => {},
}) {
  const [myHMS, setMyHMS] = useState(hms)
  const [touched, setTouched] = useState(false)
  const wrapperRef = useRef()
  const firstRef = useRef()

  function handleTimeUpdate (update = {}) {
    setMyHMS({...myHMS, ...update})
    setTouched(true)
  }

  function onCommit () {
    onUpdate(myHMS)
    setTouched(false)
  }

  function handleKeyDown (index, event) {
    if (event.key === 'Enter') onCommit()
    if (event.key === 'Tab' && !event.shiftKey && index === 2) onCommit()
  }

  const onOutsideClick = useCallback((event) => {
    if(wrapperRef?.current?.contains(event.target)) return null
    onCommit()
  }, [wrapperRef, onCommit])

  useEffect(() => {
    document.addEventListener('mousedown', onOutsideClick)
    return () => document.removeEventListener('mousedown', onOutsideClick)
  }, [onOutsideClick])

  useEffect(() => {
    firstRef?.current?.focus()
    firstRef?.current?.select()
  }, [])

  useEffect(() => {
    if (touched) return
    setMyHMS(hms)
  }, [hms])

  return (
    <div
      ref={wrapperRef} className='flex justify-between font-mono'
      onKeyDown={onKeyDown}
    >
      <input
        ref={firstRef}
        className='h-7 w-7 text-sm bg-white/20 rounded text-center focus:outline-none focus:ring'
        placeholder='00'
        value={myHMS?.hours?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ hours: Math.min(parseInt(e.target.value || 0), 23) })}
        onKeyDown={(e) => handleKeyDown(0, e)}
      />
      <input
        className='h-7 w-7 text-sm bg-white/20 rounded text-center focus:outline-none focus:ring'
        placeholder='00'
        value={myHMS?.minutes?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ minutes: Math.min(parseInt(e.target.value || 0), 59) })}
        onKeyDown={(e) => handleKeyDown(1, e)}
      />
      <input
        className='h-7 w-7 text-sm bg-white/20 rounded text-center focus:outline-none focus:ring'
        placeholder='00'
        value={myHMS?.seconds?.toLocaleString('en', { minimumIntegerDigits: 2 })}
        onChange={(e) => handleTimeUpdate({ seconds: Math.min(parseInt(e.target.value || 0), 59) })}
        onKeyDown={(e) => handleKeyDown(2, e)}
      />
    </div>
  )
}

EditableHms.propTypes = {
  hms: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
}

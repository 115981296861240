import { addMilliseconds } from 'date-fns'
import { formatDurationHuman, formatTimeOfDay } from '../../utils/formatTime.js'
import CurrentTimeOfDay from './CurrentTimeOfDay.jsx'
import PropTypes from 'prop-types'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button.jsx'
import { useEffect, useState } from 'react'
import { CUE_STATE_ACTIVE, CUE_STATE_FUTURE, CUE_STATE_NEXT } from '../../constants/cueStates.js'
import formatTimezone from '../../utils/formatTimezone.js'

export default function TimeAndOverUnder ({
  timezone,
  timestamps,
}) {
  const timeLeftToAllocate = timestamps.total - timestamps.actualTotal
  const calculatedEnd = addMilliseconds(timestamps.start, timestamps.actualTotal)

  return (
    <div className={['py-4 flex gap-6'].join(' ')}>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          Time of day { timezone ? '(' + formatTimezone(timezone, 'abbr') + ')' : '' }
        </div>
        <span className={['font-mono text-sm'].join(' ')}>
          <CurrentTimeOfDay
            timezone={timezone}
          />
        </span>
      </section>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          Calculated end
        </div>
        <span className="text-sm font-mono">{formatTimeOfDay(calculatedEnd, { timezone })}</span>
      </section>
      <section>
        <div className={['text-gray-500 font-semibold whitespace-pre text-[10px]'].join(' ')}>
          {/* Refactor me, I should not be a button! */}
          <Button
            text='Over / Under'
            size='small'
            colour='none'
            iconAfter={faQuestionCircle}
            onClick={()=>{}}
            toolTip={
              <OverUnderCalculation
                timezone={timezone}
                timestamps={timestamps}
                timeLeftToAllocate={timeLeftToAllocate}
                calculatedEnd={calculatedEnd}
              />
            }
          />
        </div>
        <span className='text-sm'>
          {timeLeftToAllocate >= 1000 ? <p className='text-green-500'><span className='font-mono'>{formatDurationHuman(timeLeftToAllocate)}</span> early</p> : ''}
          {timeLeftToAllocate == 0 ? '--' : ''}
          {timeLeftToAllocate <= -1000 ? <p className='text-red-500'><span className='font-mono'>{formatDurationHuman(timeLeftToAllocate)}</span> late</p> : ''}
        </span>
      </section>
    </div>
  )
}


TimeAndOverUnder.propTypes = {
  timezone: PropTypes.string,
  timestamps: PropTypes.object.isRequired,
}


const OverUnderCalculation = ({
  timezone,
  timestamps,
  timeLeftToAllocate,
  calculatedEnd,
}) => {
  const [elapsed, setElapsed] = useState(0)
  const [remaining, setRemaining] = useState(0)

  const handleCalculation = () => {
    let past = 0
    let future = 0
    Object.entries(timestamps.cues).map(([_, cue]) => {
      if(cue.state === CUE_STATE_FUTURE || cue.state === CUE_STATE_NEXT) {
        future = future + cue.duration
      } else if (cue.state === CUE_STATE_ACTIVE) {
        past = past + cue.elapsed
      } else {
        past = past + cue.elapsed
      }
    })
    setElapsed(past)
    setRemaining(future)
  }

  useEffect(() => {
    handleCalculation()
  }, [timestamps])
  return (
    <div className='p-2 text-sm'>
      <p className='font-semibold'>How is this calculated?</p>

      <div className='mt-3 text-xs flex flex-col gap-2'>
        <p className='text-gray-200'>Planned</p>
        <div className='flex gap-4 justify-between'>
          <p className='text-gray-400'>Start time</p>
          <p className='text-gray-400 font-mono text-right'>{formatTimeOfDay(timestamps.start, { timezone })}</p>
        </div>

        <div className='flex gap-4 justify-between'>
          <p className='text-gray-400'>End time</p>
          <p className='text-gray-400 font-mono text-right'>{formatTimeOfDay(timestamps.end, { timezone })}</p>
        </div>

        <span className='border-[.3px] border-gray-700'></span>

        <p className='text-gray-200'>Actual</p>
        <div className='flex gap-4 justify-between'>
          <p className='text-gray-400'>Elapsed cues</p>
          <p className='text-gray-400 font-mono text-right'>{formatDurationHuman(elapsed)}</p>
        </div>

        <div className='flex gap-4 justify-between'>
          <p className='text-gray-400'>Remaining cues</p>
          <p className='text-gray-400 font-mono text-right'>{formatDurationHuman(remaining)}</p>
        </div>

        <div className='flex gap-4 justify-between'>
          <p className='text-gray-400'>Calculated end time</p>
          <p className='text-gray-400 font-mono text-right'>{formatTimeOfDay(calculatedEnd, { timezone })}</p>
        </div>

        <div className='flex gap-4 justify-between'>
          <p className='text-gray-400'>Over / Under</p>
          <p className='text-gray-400'>
            {timeLeftToAllocate >= 1000 ? <><span className='font-mono'>{formatDurationHuman(timeLeftToAllocate)}</span> early</> : ''}
            {timeLeftToAllocate == 0 ? '--' : ''}
            {timeLeftToAllocate <= -1000 ? <><span className='font-mono'>{formatDurationHuman(timeLeftToAllocate)}</span> late</> : ''}
          </p>
        </div>
      </div>


    </div>
  )
}

OverUnderCalculation.propTypes = {
  timezone: PropTypes.string,
  timestamps: PropTypes.object,
  timeLeftToAllocate: PropTypes.number,
  calculatedEnd: PropTypes.any,
}

/**
 * [movePlayhead description]
 * @param  {Runner}   runner
 * @param  {Number} timeDelta
 * @return {Runner}
 */
export function movePlayhead (runner, timeDelta) {
  if (runner.timesnap.running) {
    runner.timesnap.kickoff += timeDelta
    runner.timesnap.deadline += timeDelta
  } else {
    runner.timesnap.lastStop -= timeDelta
  }
  return runner
}
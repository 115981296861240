import { useSetAtom } from 'jotai'
import Button from '../components/Button'
import Layout from '../components/Layout'
import { toastAtom } from '../store/toast.store.js'
import * as toastTypes from '../utils/toastTypes.js'

const sampleToasts = [
  {
    type: toastTypes.WARN,
    title: 'This is a warning',
    body: 'More on this!',
    duration: 5000,
    dismissible: false,
  },
  {
    type: toastTypes.FAIL,
    title: 'Oh no!',
    body: 'IT FAILED!',
    duration: 10000,
    dismissible: false,
  },
  {
    type: toastTypes.SUCCESS,
    title: 'Success!',
    body: 'That went well!',
    dismissible: true,
  },
  {
    type: toastTypes.INFO,
    title: 'INFO!',
    body: 'You should know about this!',
    duration: -1,
    dismissible: true,
  },
]

export default function Playground() {
  const addToast = useSetAtom(toastAtom)

  const handleAddNewToast = (i) => {
    addToast(sampleToasts[i])
    // addToast()
    // addToast({ type: 'warn', duration: 4000 })
  }
  return (
    <Layout title="Playground">
      <Button text="Add warn Toast" onClick={() => handleAddNewToast(0)} />
      <Button text="Add fail Toast" onClick={() => handleAddNewToast(1)} />
      <Button text="Add success Toast" onClick={() => handleAddNewToast(2)} />
      <Button text="Add info Toast" onClick={() => handleAddNewToast(3)} />
    </Layout>
  )
}

import { useEffect } from 'react'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../../store/toast.store.js'
import { pingAtom } from '../../store/runner.store.js'
import axios from 'axios'

const DEFAULT_DELAY = 10 * 1000
const WARN_TIMEOUT = 2 * 1000
const ERROR_TIMEOUT = 5 * 1000
const BACKOFF_AFTER_RETRIES = 10
const STOP_AFTER_RETRIES = 30

const pingApi = axios.create({
  timeout: ERROR_TIMEOUT,
  baseURL: import.meta.env.VITE_RUNNER_FUNCTION_BASE,
})

export default function ConnectionChecker () {
  const addToast = useSetAtom(toastAtom)
  const setPing = useSetAtom(pingAtom)

  useEffect(() => {
    let requests = 0
    let enabled = true
    let retries = 0  // Initialize retries
    let delay = DEFAULT_DELAY  // Initial delay is 2 seconds (10s)
    let connSlowToast = null
    let connLostToast = null
    let connFinalToast = null
    const controller = new AbortController()

    const pingServer = async () => {
      let connSlowTimeoutId = null
      requests++

      try {
        const start = Date.now()

        connSlowTimeoutId = setTimeout(() => {
          if (enabled && requests > 1 && !connLostToast?.visible()) {
            connSlowToast = addToast({
              title: 'Your connection is slow',
              type: 'warn',
              duration: 6000,
            })
          }
        }, WARN_TIMEOUT)

        const response = await pingApi.get('/ping', { signal: controller.signal })
        clearTimeout(connSlowTimeoutId)

        if (response.status < 400) {
          const elapsed = Date.now() - start
          if (requests > 1) setPing(elapsed)

          if (connLostToast) {
            connLostToast?.dismiss()
            connLostToast = null
            addToast({
              title: 'Reconnected successfully',
              type: 'success',
              duration: 6000,
            })
          }

          retries = 0  // Reset the retries if successful
          delay = DEFAULT_DELAY  // Reset delay to initial
        }

      } catch (error) {
        setPing(ERROR_TIMEOUT)
        clearTimeout(connSlowTimeoutId)
        connSlowToast?.dismiss()
        if (!enabled) return

        // Retry Strategy
        retries++
        if (retries >= BACKOFF_AFTER_RETRIES) delay *= 2  // Double the delay for exponential back-off

        // Connection Lost Toast
        if (!connLostToast?.visible()) {
          connLostToast = addToast({
            title: 'Connection issue. Retrying...',
            type: 'fail',
            duration: -1,
            dismissible: true,
          })
        }

        // Final connection error
        if (retries > STOP_AFTER_RETRIES) {
          connLostToast?.dismiss()
          connFinalToast = addToast({
            title: 'Can\'t reconnect to the server. Please check your network.',
            type: 'fail',
            duration: -1,
            dismissible: true,
          })
          return  // Exit the function to stop pinging
        }
      }

      if (enabled) setTimeout(pingServer, delay)  // Schedule the next ping
    }

    pingServer() // Start pinging

    // Cleanup code
    return () => {
      enabled = false
      controller.abort()
      connLostToast?.dismiss()
      connSlowToast?.dismiss()
      connFinalToast?.dismiss()
    }
  }, [addToast, setPing])

  return null
}

import { CUE_STATE_PAST, CUE_STATE_ACTIVE, CUE_STATE_NEXT, CUE_STATE_FUTURE } from '../constants/cueStates.js'
import { CUE_TYPE_CUE } from '../constants/cueTypes.js'
import addDays from 'date-fns/addDays'
import addMilliseconds from 'date-fns/addMilliseconds'
import getTimezoneOffset from 'date-fns-tz/getTimezoneOffset'
import _isEqual from 'lodash/isEqual'

let cache = []

/**
 * @typedef {object} Timestamp
 * @property {string} id – cue id
 * @property {number} index – cue index
 * @property {string} state – one of cueStates, example: 'CUE_STATE_NEXT'
 * @property {number} duration - cue duration in milliseconds, example: 60000
 * @property {number} elapsed – actual time elapsed in milliseconds, only past cues, example: 61385
 * @property {Date} plannedStart
 * @property {Date} plannedEnd
 * @property {Date} actualStart
 * @property {Date} actualEnd
 */

/**
 * @typedef {object} Timestamps
 * @property {Date} start - planned start time for rundown (date-adjusted)
 * @property {Date} end - planned end time for rundown (date-adjusted)
 * @property {number} total - total runtime in milliseconds (time between start and end)
 * @property {number} plannedTotal - total runtime in milliseconds (as allocated by durations)
 * @property {number} actualTotal - total runtime in milliseconds (as it will shake out with taking elapsed time into account)
 * @property {number} delta - over/under in milliseconds (positive is under, negative is over)
 * @property {Record<string, Timestamp>} cues - list of timestamps with `cueId: Timestamp` association
 */

/**
 * Create timestamps from rundown and runner data
 * @param  {Date} startTime
 * @param  {Date} finishTime
 * @param  {Cue[]} cues
 * @param  {Runner} runner
 * @param  {number} left - milliseconds left in current cue, usually `moment.left`
 * @param  {string} [timezone] - defaults to browser setting
 * @return {Timestamps}
 */
export default function createTimestamps (
  startTime,
  endTime,
  cues,
  runner,
  left,
  timezone,
) {
  const master = {}
  const cuesArray = Object.values(cues).map((val)=>val)
  const filteredCues = cuesArray.filter((cue) => cue.type === CUE_TYPE_CUE)
  const activeIndex = filteredCues.findIndex((cue) => cue.id === runner.timesnap?.cueId)
  const nextIndex = filteredCues.findIndex((cue) => cue.id === runner.nextCueId)

  master.start = _moveDate(startTime, { timezone })
  master.end = _moveDate(endTime, { after: master.start, timezone })
  master.total = master.end.getTime() - master.start.getTime()
  master.cues = {}

  let prevTimestamp = {
    plannedEnd: master.start,
    actualEnd: master.start,
  }

  filteredCues.forEach((cue, index) => {
    const id = cue.id
    let state
    if (runner.timesnap.ended && !runner.timesnap.running) {
      state = CUE_STATE_PAST
    } else {
      state =  _getCueState(index, activeIndex, nextIndex)
    }
    const duration = cue.duration
    const elapsed = _getActualElapsed(state, duration, runner.cuesElapsed?.[cue.id], left)
    const plannedStart = prevTimestamp.plannedEnd
    const plannedEnd = new Date(plannedStart.getTime() + duration)
    const actualStart = prevTimestamp.actualEnd
    const actualEnd = new Date(actualStart.getTime() + elapsed)

    master.cues[cue.id] = prevTimestamp = {
      id,
      index,
      state,
      duration,
      elapsed,
      plannedStart,
      plannedEnd,
      actualStart,
      actualEnd,
    }
  })

  master.delta = master.end.getTime() - prevTimestamp.actualEnd.getTime()
  master.plannedTotal = prevTimestamp.plannedEnd.getTime() - master.start.getTime()
  master.actualTotal = prevTimestamp.actualEnd.getTime() - master.start.getTime()

  if (!_isEqual(master, cache)) cache = master
  return cache
}

/**
 * Get the cue's state
 * @param  {number} cueIndex
 * @param  {number} activeIndex
 * @param  {number} nextIndex
 * @return {string}
 */
export function _getCueState (cueIndex, activeIndex, nextIndex) {
  if (cueIndex === activeIndex) return CUE_STATE_ACTIVE
  if (cueIndex === nextIndex) return CUE_STATE_NEXT
  if (cueIndex < activeIndex) return CUE_STATE_PAST
  return CUE_STATE_FUTURE
}

/**
 * Get the actual duration of a cue, taking into account `elapsed` for past cues and `left` fro current cue
 * @param  {string} state
 * @param  {number} duration
 * @param  {number} elapsed
 * @param  {number} left
 * @return {number}
 */
export function _getActualElapsed (state, duration = 0, elapsed, left) {
  switch (state) {
    case CUE_STATE_PAST:
      return elapsed || 0
    case CUE_STATE_ACTIVE:
      return Math.max(duration - left, duration)
    case CUE_STATE_NEXT:
    case CUE_STATE_FUTURE:
    default:
      return duration
  }
}

/**
 * Move a date either to today, or after a given reference date
 * @param  {Date} datetime
 * @param  {Date} [options.after]
 * @param  {string} [options.timezone] - an optional IANA timezone like 'Europe/Berlin'
 * @return {Date}
 */
export function _moveDate (
  datetime,
  {
    after,
    timezone,
  } = {},
) {
  if (after) {
    const output = _applyDate(datetime, after, timezone)
    return output < after ? addDays(output, 1) : output
  } else {
    return _applyDate(datetime, new Date(), timezone)
  }

}

/**
 * Apply year-month-day to a JS date.
 *
 * @param  {Date} time - the JS Date to be changed
 * @param  {Date} date - the JS Date to take the year-month-day from
 * @param  {string} [timezone] - an optional IANA timezone like 'Europe/Berlin'
 * @return {Date}
 */
function _applyDate (time, date, timezone = undefined) {
  const tz = timezone || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone

  // Change dates from UTC to sepcified timezone
  const timeOffset = getTimezoneOffset(tz, time)
  const timeInZone = addMilliseconds(time, timeOffset)
  const dateOffset = getTimezoneOffset(tz, date)
  const dateInZone = addMilliseconds(date, dateOffset)

  // Perform the actual applying of the date
  // Note: Order is important, year -> month -> day (otherwise funny things happen in leap years with Feb 28)
  // Note: Has to use the UTC variants to avoid interference of system timezone
  let outputInZone = new Date(timeInZone)
  outputInZone.setUTCFullYear(dateInZone.getUTCFullYear())
  outputInZone.setUTCMonth(dateInZone.getUTCMonth())
  outputInZone.setUTCDate(dateInZone.getUTCDate())
  outputInZone.setMilliseconds(0)

  // Change output from sepcified timezone back to UTC
  const inUTC = addMilliseconds(outputInZone, -getTimezoneOffset(tz, outputInZone))

  return inUTC
}

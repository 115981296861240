import axios from 'axios'
import { getUser } from './firebase.js'
import jwtDecode from 'jwt-decode'

/**
 * Instantiate Axios instances
 */
export const baseApi = axios.create({ timeout: 30000 })
export const rundownApi = baseApi.create({ baseURL: import.meta.env.VITE_RUNDOWN_FUNCTION_BASE })
export const runnerApi = baseApi.create({ baseURL: import.meta.env.VITE_RUNNER_FUNCTION_BASE })
export const teamApi = baseApi.create({ baseURL: import.meta.env.VITE_TEAM_FUNCTION_BASE })
export const auxApi = baseApi.create({ baseURL: import.meta.env.VITE_AUX_FUNCTION_BASE })
export const billingApi = baseApi.create({ baseURL: import.meta.env.VITE_BILLING_FUNCTION_BASE })
export const eventApi = baseApi.create({ baseURL: import.meta.env.VITE_EVENT_FUNCTION_BASE })

/**
 * Add request interceptor to attach the firebase auth token
 */
async function useAuth(config) {
  let idToken = null
  const user = await getUser()
  if (user) idToken = await user.getIdToken()
  if (idToken) {
    const decoded = jwtDecode(idToken)
    console.info(`Firebase Auth idToken valid for ${Math.floor(decoded.exp - (Date.now() / 1000))}s`)
    config.headers['Authorization'] = idToken
  }
  return config
}
baseApi.interceptors.request.use(useAuth)
rundownApi.interceptors.request.use(useAuth)
runnerApi.interceptors.request.use(useAuth)
teamApi.interceptors.request.use(useAuth)
auxApi.interceptors.request.use(useAuth)
billingApi.interceptors.request.use(useAuth)
eventApi.interceptors.request.use(useAuth)

/**
 * Store and handle rundown token
 */
export const RundownToken = {
  href: null,
  signature: null,
  token: null,
  intervalid: null,

  /**
   * Request a rundown token
   * @idempotent
   */
  async requestToken(rundownId, href, signature) {
    // Only run if rundownId changes or token is invalid
    if (this.rundownId === rundownId && this.isValid) return

    // Persist input data to renew token
    this.rundownId = rundownId
    this.href = href
    this.signature = signature

    // Request token
    const { data } = await rundownApi.post(`/${rundownId}/create-token`, { href, signature })
    this.token = data.token

    // Start interval to renew token
    if (!this.intervalid) this.intervalid = setInterval(() => this.renew(), 5000)
  },
  renew() {
    const hasToken = Boolean(this.rundownId) && Boolean(this.token)
    const shouldRenew = hasToken && !this.isValid
    if (shouldRenew) this.requestToken(this.rundownId, this.href, this.signature)
  },
  get isValid() {
    if (!this.token) return false
    const decoded = jwtDecode(this.token)
    const exp = new Date(decoded.exp * 1000)
    return exp >= Date.now()
  },
  get access() {
    if (!this.token) return false
    const decoded = jwtDecode(this.token)
    return decoded.access
  },
}

/**
 * Add request interceptor for rundown token
 */
function useRundownToken(config) {
  if (RundownToken.token) config.headers['Rundown-Token'] = RundownToken.token
  return config
}
rundownApi.interceptors.request.use(useRundownToken)
runnerApi.interceptors.request.use(useRundownToken)

import React, { useState } from 'react'

export default function Select({
  label = '',
  value = '',
  onChange = () => {},
  onBlur = () => {},
  placeholder = 'Enter text...',
  autofocus = false,
  disabled = false,
  required = false,
  loading = false,
  options = [],
  css,
}) {
  const [val, setVal] = useState(value || '')

  return (
    <label
      htmlFor={label}
      className={[
        'group px-3 py-2 flex flex-col bg-[#161616] text-blue-500 text-xs  border-b-2 border-transparent focus-within:border-blue-500',
        disabled && 'bg-transparent',
        loading && 'bg-animated from-gray-900 to-gray-800',
        css,
      ].join(' ')}
    >
      <span>{label}</span>
      <select
        id={label}
        disabled={disabled}
        autoFocus={autofocus}
        required={required}
        className="py-1 bg-transparent text-lg focus:outline-none placeholder:text-gray-600 text-white"
        value={val}
        placeholder={placeholder}
        onChange={(e) => {
          setVal(e.target.value)
          onChange(e.target.value)
        }}
        onBlur={onBlur}
      >
        {options.map((op, i) => {
          return (
            <option key={i} value={op.value}>
              {op.name}
            </option>
          )
        })}
      </select>
    </label>
  )
}

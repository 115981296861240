import * as Sentry from '@sentry/react'
import { version } from '../package.json'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: 'rundownstudio@' + version,
  environment: import.meta.env.VITE_PROJECT_ID,
  tracesSampleRate: 0.25,
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'timeout',
    'TimeoutError',
    'xhr poll error',
    'Unable to preload CSS',
    // 'Failed to fetch dynamically imported module',
    'Network Error',
    'safari-extension',
    'chrome-extension',
  ],
})

export function updateUserId (userId) {
  Sentry.configureScope(scope => {
    scope.setUser({ id: userId })
  })
}

export function captureException (error) {
  Sentry.captureException(error)
  if (import.meta.env.DEV) console.debug(error)
}

export default Sentry

import PropTypes from 'prop-types'
import { NodeViewWrapper } from '@tiptap/react'
import LightboxModal from '../modal/LightboxModal.jsx'
import { useState } from 'react'

/**
 * A Tiptap extension component for rendering images uploaded to the editor.
 * This component displays a the image, and allows users to open a lightbox
 * and download the image through a modal.
 */
export default function Image({ node }) {
  const [lightboxOpen, setLightboxOpen] = useState(false)

  return (
    <NodeViewWrapper className="my-2">
      <div data-drag-handle>
        <button
          type="button"
          onClick={() => setLightboxOpen(true)}
          className="block rounded shadow"
        >
          <img
            draggable="false"
            src={node.attrs.src}
            className="hover:brightness-110 transition-[filter]"
          />
        </button>
      </div>
      {lightboxOpen && <LightboxModal src={node.attrs.src} handleClose={() => setLightboxOpen(false)} />}
    </NodeViewWrapper>
  )
}

Image.propTypes = {
  node: PropTypes.shape({
    attrs: PropTypes.shape({
      src: PropTypes.string.isRequired,
    }),
  }),
}

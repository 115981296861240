import { CueLoadingContext } from './CueLoadingProvider.jsx'
import CellItemEditor from './CellItemEditor.jsx'
import { simpleEditorContentParser } from '../../utils/simpleEditorContentParser.js'
import { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function CellItemText ({
  cell,
  onUpdateCell = () => Promise.resolve(),
  eventEmitter,
  readonly = false,
  textSize = 'sm',
  className,
}) {
  const { rundownId } = useParams()
  const { isLoaded } = useContext(CueLoadingContext)
  const [isTipTapMounted, setIsTipTapMounted] = useState(false)

  const text = cell?.content?.text || ''

  async function onUpdate (text) {
    await onUpdateCell({ content: { text } })
  }

  /**
   * Handle the 'onload' event signaling that the cell if fully loaded.
   * Using `requestIdleCallback` to avoid a layout flash.
   */
  useEffect(() => {
    const onLoad = () => {
      if (typeof requestIdleCallback === 'function') requestIdleCallback(() => setIsTipTapMounted(true))
      else setTimeout(() => setIsTipTapMounted(true)) // Fallback for Safari
    }
    eventEmitter.on('onload', onLoad)
    return () => eventEmitter.off('onload', onLoad)
  }, [eventEmitter])

  /**
   * Reset `isTipTapMounted` when editor gets unloaded from DOM.
   */
  useEffect(() => {
    if (!isLoaded) setIsTipTapMounted(false)
  }, [isLoaded])

  return (
    <div className={['relative flex w-full h-full', className].join(' ')}>
      {/* Real Content (full TipTap editor) */}
      {isLoaded && (
        <div className="flex-none relative z-10 w-full h-full">
          <CellItemEditor
            content={text}
            onUpdateContent={onUpdate}
            rundownId={rundownId}
            eventEmitter={eventEmitter}
            textSize={textSize}
            readonly={readonly}
          />
        </div>
      )}

      {/* Dummy Content (for vertical height, removed after TipTap is mounted)*/}
      <div
        className={[
          'flex-none relative z-0 w-full h-full',
          (isLoaded ? '-left-full' : ''),
          (isTipTapMounted ? 'invisible' : ''),
        ].join(' ')}
      >
        <div
          className="ProseMirror ProseMirror--placeholder px-3 py-2 break-words"
          dangerouslySetInnerHTML={{ __html: simpleEditorContentParser(text) }}
        ></div>
      </div>
    </div>
  )
}

CellItemText.propTypes = {
  cell: PropTypes.object,
  onUpdateCell: PropTypes.func, // Must return a promise
  readonly: PropTypes.bool,
  textSize: PropTypes.oneOf(['sm', 'md']),
  eventEmitter: PropTypes.object,
  className: PropTypes.string,
}

import { atom } from 'jotai'

//
// Runner
//
export const runnerAtom = atom(null)

//
// Moment
//
export const momentAtom = atom(null)

//
// Ping
//
export const pingAtom = atom(0)

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import ModalWrapper from './ModalWrapper'
import PropTypes from 'prop-types'
import { CUE_BACKGROUND_COLORS } from '@rundown-studio/consts'
import { HexColorInput, HexColorPicker } from 'react-colorful'
import { useEffect, useRef, useState } from 'react'
import Button from '../Button'
import { updateRundown } from '../../firestore'
import { useParams } from 'react-router-dom'

export default function RundownColoursModal({ colours, open, setOpen }) {
  const { rundownId } = useParams()
  const [picker, setPicker] = useState(null)
  const [colors, setColours] = useState(CUE_BACKGROUND_COLORS)
  const [saving, setSaving] = useState(false)
  const [rundownColours, setRundownColours] = useState(colours)

  const colourPickerRef = useRef()

  const updateColour = (index, newColour) => {
    const newColoursArray = Array.from(colors)
    newColoursArray.splice(index, 1, newColour)

    setColours(newColoursArray)
  }

  const handleSaveCustomColours = async () =>{
    setSaving(true)
    setPicker(null)
    const { data } = await updateRundown(rundownId, { 'settings.cueBackgroundColours': colors })
    setRundownColours(data.customColours)
    setOpen(false)
    setSaving(false)
  }

  useEffect(()=>{
    if(rundownColours?.cueBackgroundColours) setColours(rundownColours.cueBackgroundColours)
  }, [rundownColours])

  return (
    <ModalWrapper
      title="Rundown colors"
      subtitle="Choose custom colors to be used on your rundown"
      open={[open, setOpen]}
      onShow={() => {}}
      onHide={() => {setPicker(null)}}
      working={saving}
      buttons={[
        {
          icon: faCheck,
          text: 'Done',
          onClick: handleSaveCustomColours,
        },
      ]}
    >
      <div className="flex flex-col gap-3 pb-6">
        <h2 className='font-semibold'>Cue background options</h2>

        <div className='justify-between grid sm:grid-cols-2'>
          <div className="max-w-xs gap-1 grid grid-cols-6">
            {colors.map((option, i) => {
              return (
                <div key={i} className='relative'>
                  <button
                    style={{
                      backgroundColor: option,
                    }}
                    className={[
                      'w-8 h-8 hover:brightness-125 focus:outline-none focus:ring rounded border border-gray-700',
                      picker === i && 'ring',
                    ].join(' ')}
                    onClick={() => setPicker(picker === i ? null : i)}
                  ></button>
                  {picker === i &&
                  <div ref={colourPickerRef} className='absolute z-50'>
                    <HexColorPicker color={option} onChange={(newColour)=>{ updateColour(i, newColour) }} />
                    <div className='flex mt-2 gap-2'>
                      <HexColorInput
                        color={option}
                        onChange={(newColour)=>{ updateColour(i, newColour) }}
                        className='h-9 text-gray-200 bg-gray-800 border border-gray-700 px-1 rounded mx-auto w-full text-center focus:ring focus:outline-none'
                      />
                      <Button text='Close' onClick={()=>setPicker(null)} />
                    </div>
                  </div>
                  }
                </div>
              )
            })}
            <Button text='Reset all to default'
              size='small'
              colour='none'
              css='max-w-sm text-xs mx-auto'
              onClick={()=>{
                setColours(CUE_BACKGROUND_COLORS)
              }}
            />
          </div>

          <div className='bg-black h-20 w-100 rounded flex items-center justify-center gap-10'>
            <div
              style={{
                backgroundColor: picker >= 0 ? colors[picker] : 'transparent',
              }}
              className={[
                'p-6 pr-20 h-10 w-100 rounded flex items-center justify-center gap-10',
              ].join(' ')}
            >
              <span>3m 20s</span>
              <span className='font-semibold'>Opening welcome</span>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

RundownColoursModal.propTypes = {
  colours: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import ContextMenu from './ContextMenu'
import { useState } from 'react'
import { Tooltip, TooltipTrigger, TooltipContent } from './interactives/Tooltip.jsx'

/**
 * Note for the future:
 * This button component does way too much! Better have two components, one for the button and another for the
 * dropdown menu. Also the tooltip functionality should be handeled outside the Button.
 * Single responsibility principle: A component should do one thing.
 */

export default function Button ({
  text = '',
  title = undefined,
  disabled = false,
  loading = false,
  css = '',
  className = '',
  icon = null,
  onClick = () => {},
  colour = 'main',
  size = 'md',
  toolTip = '',
  type = 'button',
  secondaryMenu = false,
  secondaryMenuItems = [],
  secondaryMenuOnClick = () => {},
  iconAfter = null,
  showTooltipWhenDisabled = false,
  ...props
}) {
  const [contextOpen, setContextOpen] = useState(false)

  const iconOnly = !!icon && !text
  const h = { sm: 'h-7', md: 'h-9' }
  const w = { sm: 'w-7', md: 'w-9' }
  const px = { sm: 'px-1', md: 'px-3' }
  const font = { sm: 'text-sm', md: 'text-md' }

  const buttonComp = (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      title={title}
      className={[
        'relative inline-flex items-center justify-center hover:brightness-125 disabled:brightness-100 transition-[filter] font-semibold disabled:opacity-50 disabled:cursor-default focus:outline-none focus:ring focus:ring-offset-1 focus:ring-offset-transparent whitespace-nowrap',
        (colour === 'main' && 'bg-blue-500 text-white'),
        (colour === 'red' && 'bg-red-500 text-white'),
        (colour === 'dark' && 'bg-gray-900 text-gray-200'),
        h[size],
        (iconOnly ? w[size] : px[size]),
        (text && font[size]),
        (loading && colour === 'main' && 'bg-animated from-blue-700 to-blue-600'),
        (loading && colour === 'red' && 'bg-animated from-red-700 to-red-600'),
        (loading && colour === 'dark' && 'bg-animated from-gray-800 to-gray-600'),
        (secondaryMenu ? 'rounded-l' : 'rounded'),
        (icon && '!pl-0'),
        (iconAfter && '!pr-0'),
        className || css,
      ].join(' ')}
    >
      {icon && (
        <span className={
          [
            'text-center',
            size === 'sm' && 'w-7',
            size === 'md' && 'w-9',
          ].join(' ')}>
          <FontAwesomeIcon icon={icon} size="sm" fixedWidth />
        </span>
      )}
      <span className='text-ellipsis overflow-hidden'>{text}</span>
      {iconAfter && (
        <span className={
          [
            'text-center',
            size === 'sm' && 'w-7',
            size === 'md' && 'w-9',
          ].join(' ')}>
          <FontAwesomeIcon icon={iconAfter} fixedWidth />
        </span>
      )}
    </button>
  )

  return (
    <div
      className="flex"
      {...props}
    >
      {toolTip ? (
        <Tooltip disabled={disabled && !showTooltipWhenDisabled}>
          <TooltipTrigger className="w-full">
            {buttonComp}
          </TooltipTrigger>
          <TooltipContent>
            {toolTip}
          </TooltipContent>
        </Tooltip>
      ) : (
        buttonComp
      )}
      {secondaryMenu &&
        <>
          <button
            onClick={(e) => setContextOpen(e.nativeEvent)}
            disabled={disabled}
            className={[
              'bg-gray-900 px-1 rounded-r border-l-2 border-gray-800 hover:brightness-125 focus:outline-none focus:ring focus:ring-offset-1 focus:ring-offset-transparent focus:z-10 cursor-pointer disabled:pointer-events-none disabled:opacity-50',
              h[size],
              (loading && colour === 'main' && 'bg-animated from-blue-700 to-blue-600'),
              (loading && colour === 'dark' && 'bg-animated from-gray-800 to-gray-600'),
            ].join(' ')}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
          {contextOpen && <ContextMenu
            openEvent={contextOpen}
            items={secondaryMenuItems}
            onClick={(choice) => secondaryMenuOnClick(choice)}
            onClose={() => setContextOpen(null)}
          />}
        </>
      }
    </div>
  )
}

Button.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  css: PropTypes.string, // OBSOLETE, use className instead
  size: PropTypes.string,
  icon: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func,
  colour: PropTypes.oneOf(['main', 'dark', 'red', 'none']),
  toolTip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  secondaryMenu: PropTypes.bool,
  secondaryMenuItems: PropTypes.array,
  secondaryMenuOnClick: PropTypes.func,
  iconAfter: PropTypes.any,
  className: PropTypes.string,
  showTooltipWhenDisabled: PropTypes.bool,
}

let resolveMe = null
const awaitMe = new Promise(resolve => resolveMe = resolve)
let initDone = false

export default class PaddleLoader {
  static async getInstance () {
    PaddleLoader._loadPaddleScript()
    await awaitMe
    if (typeof window.Paddle !== 'undefined') return window.Paddle
    else throw new Error ('Error loading Paddle')
  }

  // Idempotent function
  static _loadPaddleScript () {
    let scriptElement = document.getElementById('script-paddle-js')
    if (!scriptElement) {
      scriptElement = document.createElement('script')
      scriptElement.setAttribute('src', 'https://cdn.paddle.com/paddle/v2/paddle.js')
      scriptElement.setAttribute('type', 'text/javascript')
      scriptElement.setAttribute('id', 'script-paddle-js')
      scriptElement.setAttribute('data-injected', 'true')
      document.body.appendChild(scriptElement)
    }
    if (typeof window.Paddle !== 'undefined') PaddleLoader._initPaddle()
    else scriptElement.addEventListener('load', () => { PaddleLoader._initPaddle() })
  }

  // Idempotent function
  static _initPaddle () {
    if (!initDone) {
      let seller = parseInt(import.meta.env.VITE_PADDLE_VENDOR_ID)
      if (import.meta.env.VITE_PADDLE_SANDBOX === 'ON') window.Paddle.Environment.set('sandbox')

      if (seller) {
        window.Paddle.Setup({
          seller,
          checkout: {
            settings: {
              theme: 'dark',
            },
          },
          eventCallback: function(data) {
            console.log('eventCallback', data)
            if (data.name === 'checkout.completed') {
              console.log(data)
            } else if (data.name === 'checkout.closed'){
              return window.location = '/dashboard'
            }
          },
        })
      }
      initDone = true
    }
    resolveMe()
  }
}

/**
 * Takes a firestore doc and returns a doc with parsed dates
 * @param  {FsEventRunner} firestoreDoc
 * @return {EventRunner}
 */
export default function parseEventRunner(firestoreDoc) {
  const parsedDoc = firestoreDoc.data()
  parsedDoc.id = firestoreDoc.id
  return parsedDoc
}

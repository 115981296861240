import { useSetAtom } from 'jotai'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import { getUser, getUserTeams } from '../../firebase'
import _find from 'lodash/find'
import { rundownListAtom } from '../../store/rundownList.store'

export default function DashboardCheck () {
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const setRundownsList = useSetAtom(rundownListAtom)
  // const setUser = useSetAtom(userAtom)
  // const setTeams = useSetAtom(userTeamsAtom)
  // const setActiveTeam = useSetAtom(userTeamAtom)

  const handleNavigate = async (teamId) => {
    // Clean out rundowns list
    setRundownsList(null)
    return navigate(`/dashboard/${teamId}/`, { replace: true })
  }

  const handleCheckDefaultTeam = async () => {
    const awaitedUser = await getUser()
    if(!awaitedUser) return navigate('/login', { replace: true })

    // Check user's teams, and retry if functions are still creating team
    let i = 0
    let awaitedUserTeams = []
    while (i++ < 5) {
      awaitedUserTeams = await getUserTeams()
      if (awaitedUserTeams.length) break
      await new Promise((r) => setTimeout(r, 5000))
    }

    // Still didn't find user's team
    if (awaitedUserTeams.length < 1) return setMessage('No teams found')
    // setTeams(awaitedUserTeams)

    const teamId = localStorage.getItem('teamId')
    const isUserInTeam = _find(awaitedUserTeams, (team) => team.id === teamId)

    if (teamId && isUserInTeam) {
      // setActiveTeam(isUserInTeam)
      return handleNavigate(teamId)
    } else {
      const tId = awaitedUserTeams[0]?.id
      // setActiveTeam(awaitedUserTeams[0])
      localStorage.setItem('teamId', tId)
      return handleNavigate(tId)
    }
  }

  useEffect(() => {
    handleCheckDefaultTeam()
  }, [])

  return <Layout title="Loading..." loading={!message}>{message && <p className="text-sm text-red-500">{message}</p>}</Layout>
}

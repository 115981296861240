import { faBolt, faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'


export default function UpgradeMessage ({message = '', className}) {
  return (
    <div className={['text-sm pt-2', className].join(' ')}>
      <FontAwesomeIcon icon={faBolt} className='text-yellow-500' /> {message} <a href='https://rundownstudio.app#pricing' className='underline whitespace-nowrap' target='_blank' rel="noreferrer">See pricing <FontAwesomeIcon icon={faExternalLink} /></a>
    </div>
  )
}

UpgradeMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
}

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createPortal } from 'react-dom'
import Button from '../Button'
import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const TRANSITION_DURATION = 300

const STATE = {
  HIDDEN: 'HIDDEN',
  BEFORE_ENTER: 'BEFORE_ENTER',
  ENTER: 'ENTER',
  VISIBLE: 'VISIBLE',
  EXIT: 'EXIT',
  AFTER_EXIT: 'AFTER_EXIT',
}

function Portal({ children }) {
  return createPortal(children, document.getElementById('modal-root'))
}

export default function ModalWrapper({
  open,
  title = '',
  subtitle = '',
  children,
  width = 800,
  buttons = [],
  closeable = true,
  onShow = null,
  onHide = null,
  working = false,
  className = '',
  bodyClass = '',
  ...props
}) {
  const modalChildRef = useRef()
  const [isOpen, setIsOpen] = open
  const [state, setState] = useState(STATE.HIDDEN)

  useEffect(() => {
    if (isOpen) {
      onShow?.()
      setState(STATE.BEFORE_ENTER)
      setTimeout(() => setState(STATE.ENTER), 50)
      setTimeout(() => setState(STATE.VISIBLE), TRANSITION_DURATION)
    } else {
      onHide?.()
      setState(STATE.EXIT)
      setTimeout(() => {
        setState(STATE.AFTER_EXIT)
        setTimeout(() => setState(STATE.HIDDEN), 50)
      }, TRANSITION_DURATION)
    }
    // eslint-disable-next-line
  }, [isOpen])

  const handleOnClose = () => {
    setIsOpen(false)
  }

  const handleOutsideClick = (event) => {
    event.stopPropagation()
    if (!closeable) return
    if (modalChildRef?.current.contains(event.target)) return
    handleOnClose()
  }

  if (state === STATE.HIDDEN) return null

  return (
    <Portal>
      <div className="fixed inset-0 z-[1000]" onMouseDown={handleOutsideClick}>
        {/* Background */}
        <div
          className={[
            'fixed z-[1001] inset-0 bg-black/50 transition-opacity duration-300 ease-out backdrop-blur',
            [STATE.HIDDEN, STATE.BEFORE_ENTER, STATE.EXIT, STATE.AFTER_EXIT].includes(state) ? 'opacity-0' : '',
            [STATE.ENTER, STATE.VISIBLE].includes(state) ? 'opacity-100' : '',
          ].join(' ')}
        ></div>

        {/* Modal */}
        <div className="fixed z-[1002] inset-0 overflow-y-auto">
          <div
            style={{ width: `${width}px` }}
            className={[
              'max-w-[90vw] transition duration-300 ease-out mx-auto my-12',
              [STATE.HIDDEN, STATE.BEFORE_ENTER, STATE.EXIT, STATE.AFTER_EXIT].includes(state) ? 'opacity-0 scale-95' : '',
              [STATE.ENTER, STATE.VISIBLE].includes(state) ? 'opacity-100 scale-100' : '',
              className,
            ].join(' ')}
            {...props}
          >
            <section ref={modalChildRef} className="bg-gray-900 rounded">
              <header className="flex justify-between items-center px-6 h-16">
                <div>
                  <h2 className="text-2xl font-semibold">{title}</h2>
                  {subtitle && <p className='text-xs pt-2 text-gray-300'>{subtitle}</p>}
                </div>
                {closeable ? (
                  <button
                    className="text-gray-500 hover:text-white w-9 h-9 -mr-3"
                    onClick={handleOnClose}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </button>
                ) : (
                  ''
                )}
              </header>
              <div className={['px-6 pb-6', bodyClass].join(' ')}>
                {children}
              </div>
              {buttons.length ? (
                <footer className="flex items-center justify-center gap-3 px-6 pb-6">
                  {buttons.map((button, i) => (
                    <Button
                      key={`modal-button-${i}`}
                      text={button.text}
                      onClick={button.onClick}
                      className={[
                        '!bg-gray-800',
                        button.className,
                        button.css, // Legacy
                      ].join(' ')}
                      icon={button.icon}
                      loading={working}
                      colour={button.colour}
                    />
                  ))}
                </footer>
              ) : (
                ''
              )}
            </section>
          </div>
        </div>
      </div>
    </Portal>
  )
}

ModalWrapper.propTypes = {
  open: PropTypes.array.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  width: PropTypes.number,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func.isRequired,
      css: PropTypes.string,
      icon: PropTypes.object,
    }),
  ),
  closeable: PropTypes.bool,
  onShow: PropTypes.func,
  onHide: PropTypes.func,
  working: PropTypes.bool, // The modal is doing work behind the scenes
  className: PropTypes.string,
  bodyClass: PropTypes.string,
}

import { useState } from 'react'
import Button from '../components/Button'
import Input from '../components/Input'
import Layout from '../components/Layout'
import { getValidCode } from '../firestore'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../store/toast.store'

export default function Code() {
  const [checking, setChecking] = useState(false)
  const [codeId, setCodeId] = useState('')
  const addToast = useSetAtom(toastAtom)

  const regexValidaiton = /^\d{6}$/

  const handleCheckCode = async (e) => {
    e.preventDefault()

    if (!regexValidaiton.test(codeId)) {
      return addToast({
        title: 'Invalid Code',
        body: 'Code must be 6 digits.',
        type: 'warn',
      })
    }

    setChecking(true)

    try {
      const { data } = await getValidCode(codeId)
      setCodeId('')
      setChecking(false)
      return (window.location.href = data.url)
    } catch (error) {
      console.error(error)
      setChecking(false)
    }
  }

  return (
    <Layout title="Quick access code">
      <form
        className="flex flex-col gap-4"
        onSubmit={(e) => {
          handleCheckCode(e)
        }}
      >
        <Input label="Enter your code" placeholder="654321" value={codeId} onChange={(change) => setCodeId(change)} autofocus={true} required={true} />
        <Button disabled={checking} loading={checking} text={checking ? 'Checking code...' : 'Take me there'} onClick={handleCheckCode} />
      </form>
    </Layout>
  )
}

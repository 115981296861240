import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

export default function RundownOutputControls ({ onToggleFullscreen, mirrorVertically, onMirrorVertically, mirrorHorizontally, onMirrorHorizontally }) {
  const isMac = useCallback(()=> /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform), [navigator])
  return (
    <div className="flex gap-1">
      <div
        className={[
          'px-4 h-10 rounded-lg bg-gray-800/90 flex items-center justify-center gap-2',
        ].join(' ')}
      >
        Use 
        <span className='py-1 px-2 rounded bg-gray-950/70 border border-gray-600 font-mono text-xs'>{isMac() ? 'CMD' : 'CTRL'} +</span>
        and
        <span className='py-1 px-2 rounded bg-gray-950/70 border border-gray-600 font-mono text-xs'>{isMac() ? 'CMD' : 'CTRL'} -</span>
        to zoom in and out 
      </div>
      <button
        className={[
          'h-10 w-10 rounded-lg bg-gray-800/90 hover:bg-gray-800 transition-colors flex items-center justify-center',
          (mirrorVertically && 'border border-green-500'),
        ].join(' ')}
        onClick={onMirrorVertically}
      >
        <img className='h-6 w-6 text-white' src='/img/mirror-icon.svg' />
      </button>
      <button
        className={[
          'h-10 w-10 rounded-lg bg-gray-800/90 hover:bg-gray-800 transition-colors flex items-center justify-center',
          (mirrorHorizontally && 'border border-green-500'),
        ].join(' ')}
        onClick={onMirrorHorizontally}
      >
        <img className='h-6 w-6 text-white rotate-90' src='/img/mirror-icon.svg' />
      </button>
      <button
        className={['h-10 w-10 rounded-lg bg-gray-800/90 hover:bg-gray-800 transition-colors']}
        onClick={onToggleFullscreen}
      >
        <FontAwesomeIcon icon={faExpand} />
      </button>
    </div>
  )
}

RundownOutputControls.propTypes = {
  onToggleFullscreen: PropTypes.func.isRequired,
  mirrorVertically: PropTypes.bool.isRequired,
  onMirrorVertically: PropTypes.func.isRequired,
  mirrorHorizontally: PropTypes.bool.isRequired,
  onMirrorHorizontally: PropTypes.func.isRequired,
}

import { atom } from 'jotai'

export const rundownListAtom = atom([])
export const removeRundownAtom = atom(
  null, // write-only atom
  (get, set, id) => {
    const list = get(rundownListAtom)
    set(rundownListAtom, list.filter((item) => item.id !== id))
  },
)
export const updateRundownAtom = atom(
  null, // write-only atom
  (get, set, rundown) => {
    const list = get(rundownListAtom)
    set(
      rundownListAtom,
      list.map((item) => {
        if (item.id !== rundown.id) return item
        return { ...rundown }
      }),
    )
  },
)
export const addRundownAtom = atom(
  null, // write-only atom
  (get, set, rundown) => {
    const list = get(rundownListAtom)
    set(rundownListAtom, [...list, rundown])
  },
)

import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

export default function ScrollIntoView({ following = true, marginTop = '-150px' }) {
  const scrollRef = useRef(null)

  useEffect(() => {
    if (following && scrollRef.current) {
      scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [following])

  return <div ref={scrollRef} style={{marginTop}} className="absolute"></div>
}

ScrollIntoView.propTypes = {
  following: PropTypes.bool,
  marginTop: PropTypes.string,
}
import PropTypes from 'prop-types'
import { CueItemElapsed } from '../CueItemHeader'
import { useSearchParams } from 'react-router-dom'
import * as OUTPUT_TYPES from '../../../constants/outputConfigTypes'
import CurrentTimeOfDay from '../CurrentTimeOfDay'
import RundownTextFitWrapper from './RundownTextFitWrapper'


export default function RundownOutputHeroCue ({ cues, moment, runner }) {
  const [ searchParams ] = useSearchParams()

  const configLargeTimeOfDay = searchParams.get(OUTPUT_TYPES.LARGE_TIME_OF_DAY) || OUTPUT_TYPES.OFF
  const configNextCue = searchParams.get(OUTPUT_TYPES.NEXT_CUE) || OUTPUT_TYPES.OFF

  const currentCue = runner.timesnap?.cueId ? cues[moment?.cueId] : null
  const nextCue = runner.nextCueId ? cues[runner.nextCueId] : null

  return (
    <div className='overflow-hidden'>
      {/* FIRST */}
      <div
        style={{ height: '18vh'}}
      >
        {configLargeTimeOfDay === OUTPUT_TYPES.ON &&
        <RundownTextFitWrapper height='18vh'>
          <LargeTimeOfDay className='opacity-60' />
        </RundownTextFitWrapper>}
      </div>


      {/* SECOND */}
      <div
        style={{ height: '40vh'}}
      >
        <RundownTextFitWrapper height='40vh' className='font-mono font-semibold'>
          {currentCue ?
            <CueItemElapsed moment={moment} className={'!bg-transparent'} />
            :
            <span className='opacity-50'>00:00</span>
          }
        </RundownTextFitWrapper>
      </div>

      {/* THIRD */}
      <div
        style={{ height: '16vh'}}
      >
        <div
          style={{ height: '10vh'}}
        >
          <RundownTextFitWrapper height='7vh' multiLine={true}>
            {currentCue?.title}
          </RundownTextFitWrapper>
          <RundownTextFitWrapper height='4vh' multiLine={true}>
            {currentCue?.subtitle}
          </RundownTextFitWrapper>
        </div>
      </div>

      {/* FORTH */}
      <div
        style={{ height: '14vh'}}
      >
        {configNextCue === OUTPUT_TYPES.ON &&
        <RundownTextFitWrapper height='4vh' className='opacity-60'>
          {nextCue && <div className='flex items-start gap-[3svw]'
            style={{ height: '4vh'}}
          >
            <div className='bg-white text-black px-[0.5vh] font-semibold'>
              NEXT
            </div>
            <div>
              {nextCue?.title}
            </div>
          </div>}
        </RundownTextFitWrapper>}
      </div>

    </div>
  )
}

RundownOutputHeroCue.propTypes = {
  rundown: PropTypes.object,
  cues: PropTypes.object.isRequired,
  timestamps: PropTypes.object.isRequired,
  moment: PropTypes.object.isRequired,
  runner: PropTypes.object.isRequired,
}

export const LargeTimeOfDay = ({ className = 'opacity-90', timezone}) => {
  return (
    <div className={[
      'font-mono font-semibold whitespace-nowrap',
      className,
    ].join(' ')}><CurrentTimeOfDay timezone={timezone} /></div>
  )
}

LargeTimeOfDay.propTypes = {
  className: PropTypes.string,
  timezone: PropTypes.string,
}
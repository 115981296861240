import { faBolt, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import ModalWrapper from './ModalWrapper'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function UpgradeModal({ open, setOpen, message }) {
  

  return (
    <ModalWrapper
      title="Time to upgrade"
      open={[open, setOpen]}
      onShow={() => {}}
      onHide={() => {}}
      buttons={[
        {
          icon: faBolt,
          text: 'Upgrade my account',
          onClick:  () => window.open('https://rundownstudio.app#pricing', '_blank'),
        },
      ]}
    >
      <div className="flex flex-col gap-6 pb-4">
        <div>{message}</div>
        <div className='text-gray-400'>
          <p className='flex gap-2 items-center'><FontAwesomeIcon icon={faCheckCircle} />Add more rundowns to your account</p>
          <p className='flex gap-2 items-center'><FontAwesomeIcon icon={faCheckCircle} />Unlimited cues and columns per rundown</p>
          <p className='flex gap-2 items-center'><FontAwesomeIcon icon={faCheckCircle} />Invite team members for collaboration</p>
          <p className='flex gap-2 items-center'><FontAwesomeIcon icon={faCheckCircle} />Upload CSV for faster rundown building</p>
        </div>
      </div>
    </ModalWrapper>
  )
}

UpgradeModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
}
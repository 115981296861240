import { Node } from '@tiptap/core'
import { ReactNodeViewRenderer, mergeAttributes } from '@tiptap/react'
import FileExtension from './FileExtension.jsx'

export default Node.create({
  name: 'fileComponent',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      filename: {
        default: '',
      },
      size: {
        default: '',
      },
      type: {
        default: '',
      },
      url: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'file-component',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['file-component', mergeAttributes(HTMLAttributes)]
  },


  addNodeView() {
    return ReactNodeViewRenderer(FileExtension)
  },
})

import { faArrowRightLong, faCog, faCopy, faLock, faLockOpen, faPlus, faTrashAlt, faGripLines, faChevronDown, faChevronRight, faPalette, faForwardStep } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import PropTypes from 'prop-types'
import ContextMenu from '../ContextMenu'
import { CUE_BACKGROUND_COLORS } from '@rundown-studio/consts'
import { CUE_TYPE_CUE, CUE_TYPE_GROUP, CUE_TYPE_HEADING } from '../../constants/cueTypes.js'
import ModalWrapper from '../modal/ModalWrapper.jsx'

export default function CueItemSettings ({
  cue,
  readonly,
  onUpdateCue,
  onDeleteCue,
  onAddCue,
  onDuplicateCue,
  onJumpToCue,
  onSetNext,
  handleItemCollapse,
  collapsedGroups,
  parentId,
  setRundownColoursModalOpen,
  cueBackgroundColours,
}) {
  const [showContext, setShowContext] = useState(null)
  const [deletingGroup, setDeletingGroup] = useState(false)

  const handleItemClick = async (item, secondary) => {
    setShowContext(false)
    switch (item) {
      case 'colour_picker':
        return onUpdateCue({ backgroundColor: secondary })
      case 'edit_rundown_colors':
        return setRundownColoursModalOpen(true)
      case 'jump_to_cue':
        return onJumpToCue()
      case 'cue_up_next':
        return onSetNext()
      case 'change_type':
        return onUpdateCue({ type: secondary })
      case 'add_cue':
        return onAddCue({ position: secondary })
      case 'duplicate':
        return onDuplicateCue()
      case 'lock':
        return onUpdateCue({ locked: !cue.locked })
      case 'delete':
        return onDeleteCue()
      case 'delete-group':
        return setDeletingGroup(true)
      default:
        return console.info(item, secondary)
    }

  }
  if (readonly) return null
  return (
    <div className={[
      'flex flex-col h-full group-hover:backdrop-blur min-h-14',
      cue.type === CUE_TYPE_HEADING ? 'justify-center' : 'justify-between ',
    ].join(' ')}>
      {/* Drag Handle */}
      {cue.locked ?
        <span className='h-7'></span>
        :
        <button
          className={[
            'flex-none w-full opacity-0 group-hover:opacity-70 hover:!opacity-100 cursor-grab rounded focus:outline-none focus:ring',
            cue.type === CUE_TYPE_HEADING ? 'transition-all h-0 group-hover:h-7' : 'h-7 ',
          ].join(' ')}
          data-draghandle
        >
          <FontAwesomeIcon icon={faGripLines} />
        </button>}
      {/* Gear Button */}
      <button
        className={[
          'flex-none w-full h-7 rounded  group-hover:opacity-70 hover:!opacity-100 transition-opacity focus:outline-none focus:ring',
          parentId ? 'opacity-0' : 'opacity-30',
        ].join(' ')}
        onClick={(event) => setShowContext(event.nativeEvent)}
      >
        <FontAwesomeIcon icon={faCog} size='sm' />
      </button>
      {/* Lock Button OR Collapse button */}
      {cue.type === CUE_TYPE_GROUP ?
        <button
          className={[
            'flex-none w-full h-7 rounded opacity-70 text-gray-500 group-hover:opacity-70 hover:!opacity-100 transition-opacity text-xs focus:outline-none focus:ring',
          ].join(' ')}
          onClick={() => handleItemCollapse(cue.id)}
        >
          <FontAwesomeIcon icon={collapsedGroups?.includes(cue.id) ? faChevronRight : faChevronDown} />
        </button>
        : cue.type === CUE_TYPE_CUE && <button
          className={[
            'flex-none w-full h-7 rounded group-hover:opacity-70 hover:!opacity-100 transition-opacity text-xs focus:outline-none focus:ring',
            (cue.locked ? 'opacity-70 text-yellow-500' : 'opacity-0'),
          ].join(' ')}
          onClick={() => handleItemClick('lock')}
        >
          <FontAwesomeIcon icon={cue.locked ? faLock : faLockOpen} size='sm' />
        </button>
      }
      {showContext && (
        <ContextMenu
          openEvent={showContext}
          onClose={() => setShowContext(null)}
          onClick={handleItemClick}
          items={[
            {
              name: 'Colours',
              value: 'colour_picker',
              type: 'colour',
              options: cueBackgroundColours || CUE_BACKGROUND_COLORS,
            },
            {
              name: 'Edit rundown colors',
              value: 'edit_rundown_colors',
              icon: faPalette,
            },
            {
              type: 'spacer',
            },
            {
              name: 'Jump to this cue',
              value: 'jump_to_cue',
              icon: faForwardStep,
              disabled: [CUE_TYPE_HEADING, CUE_TYPE_GROUP].includes(cue.type),
            },
            {
              name: 'Cue up next',
              value: 'cue_up_next',
              icon: faArrowRightLong,
              disabled: [CUE_TYPE_HEADING, CUE_TYPE_GROUP].includes(cue.type),
            },
            {
              name: 'Duplicate cue',
              value: 'duplicate',
              icon: faCopy,
              disabled: cue.type === CUE_TYPE_GROUP,
            },

            {
              name: 'Add cue...',
              value: 'add_cue',
              type: 'secondary',
              options: [
                {
                  name: 'Add cue above',
                  value: 'before',
                  icon: faPlus,
                },
                {
                  name: 'Add cue below',
                  value: 'after',
                  icon: faPlus,
                },
              ],
            },
            {
              name: 'Convert to...',
              value: 'change_type',
              type: 'secondary',
              options: [
                {
                  name: 'Cue',
                  value: CUE_TYPE_CUE,
                },
                {
                  name: 'Heading',
                  value: CUE_TYPE_HEADING,
                },
              ],
              disabled: cue.type === CUE_TYPE_GROUP,
            },
            {
              name: cue.locked ? 'Unlock' : 'Lock cue',
              value: 'lock',
              icon: cue.locked ? faLockOpen : faLock,
            },
            {
              type: 'spacer',
            },
            {
              name: cue.type === CUE_TYPE_GROUP ? 'Delete group' : 'Delete cue',
              value: cue.type === CUE_TYPE_GROUP ? 'delete-group' : 'delete',
              icon: faTrashAlt,
              disabled: cue.locked,
            },
          ]}
        />
      )}

      {deletingGroup &&
      <ModalWrapper
        open={[deletingGroup, setDeletingGroup]}
        title='Delete group and sub-cues'
        buttons={[
          {
            text: 'Cancel',
            onClick: () => setDeletingGroup(false),
          },
          {
            text: 'Delete Group and all sub-cues',
            icon: faTrashAlt,
            onClick: async () => {
              setDeletingGroup(false)
              onDeleteCue()
            },
          },
        ]}
      >
        <p>Deleting this group will also delete all sub-cues within the group.</p>
      </ModalWrapper>}
    </div>
  )
}

CueItemSettings.propTypes = {
  cue: PropTypes.object.isRequired,
  readonly: PropTypes.bool,
  onUpdateCue: PropTypes.func.isRequired,
  onDeleteCue: PropTypes.func.isRequired,
  onAddCue: PropTypes.func.isRequired,
  onDuplicateCue: PropTypes.func.isRequired,
  onJumpToCue: PropTypes.func.isRequired,
  onSetNext: PropTypes.func.isRequired,
  handleItemCollapse: PropTypes.func.isRequired,
  collapsedGroups: PropTypes.array.isRequired,
  parentId: PropTypes.string,
  setRundownColoursModalOpen: PropTypes.func,
  cueBackgroundColours: PropTypes.array,
}

import { useSetAtom } from 'jotai'
import DashboardWrapper from './DashboardWrapper'
import {  userTeamAtom } from '../../store/user.store.js'
import {  inviteTeamMember, membersMetadata, removeTeamMember, updateTeam } from '../../firestore'
import { useOutletContext, useParams } from 'react-router-dom'
import Input from '../Input'
import { useCallback, useEffect, useState } from 'react'
import Button from '../Button'
import { faBolt, faEllipsis, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TeamMemberRoles } from '../../constants/teamRoles'
import ModalWrapper from '../modal/ModalWrapper'
import Select from '../Select'
import ContextMenu from '../ContextMenu'
import { format } from 'date-fns'
import UpgradeModal from '../modal/UpgradeModal'
import PropTypes from 'prop-types'

const newTeamMemberDefaults = {
  role: TeamMemberRoles.team_member.name,
  email: '',
}

export default function Team() {
  const { user, team: activeTeam, plan } = useOutletContext()
  const { teamId } = useParams()
  const setActiveTeam = useSetAtom(userTeamAtom)
  const [newTeamName, setNewTeamName] = useState(activeTeam.name)
  const [activeTeamWithMeta, setActiveTeamWithMeta] = useState(activeTeam)
  const [loadingTeamName, setLoadingTeamName] = useState(false)
  const [loadingTeamWithMeta, setLoadingTeamWithMeta] = useState(false)
  const [showAddTeamMemberModal, setShowAddTeamMemberModal] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [newTeamMember, setNewTeamMember] = useState(newTeamMemberDefaults)

  const teamMemberRunningTotal =  Object.entries(activeTeam?.members || {}).length + Object.entries(activeTeam?.pending || {}).length
  const teamMemberLimit =  plan?.limits?.members || 1
  const exceededTeamMemberLimit = teamMemberLimit === -1 ? false : teamMemberRunningTotal >= teamMemberLimit

  const isTeamAdmin = useCallback(() => {
    if (activeTeam?.members?.[user?.uid]?.role === TeamMemberRoles.team_admin.name) {
      return true
    }

    return false
  }, [user, activeTeam])

  const handleGetActiveTeamWithMeta = async () => {
    setLoadingTeamWithMeta(true)
    const { data } = await membersMetadata(teamId)
    setActiveTeamWithMeta(data)
    setLoadingTeamWithMeta(false)
  }

  const handleAddTeamMember = async () => {
    if (!newTeamMember.email) return setLoadingTeamWithMeta(false)
    setLoadingTeamWithMeta(true)
    const { data } = await inviteTeamMember(activeTeam?.id, newTeamMember)
    setActiveTeam({ ...activeTeam, pending: data.pending })
    setLoadingTeamWithMeta(false)
    setShowAddTeamMemberModal(false)
  }

  const handleRemoveTeamMember = async (emailOrUid) => {
    setLoadingTeamWithMeta(true)
    const { data } = await removeTeamMember(activeTeam?.id, emailOrUid)
    setActiveTeam({ ...activeTeam, members: data.members, pending: data.pending })
    setLoadingTeamWithMeta(false)
    setShowAddTeamMemberModal(false)
  }

  const handleUpdateTeamName = async () => {
    setLoadingTeamName(true)
    await updateTeam(teamId, { name: newTeamName })
    setActiveTeam({ ...activeTeam, name: newTeamName })
    setLoadingTeamName(false)
  }

  useEffect(() => {
    handleGetActiveTeamWithMeta()
  }, [activeTeam.id])

  return (
    <DashboardWrapper title="Team">
      <div className="grid grid-cols-2">
        <Input
          label="Team name"
          value={newTeamName}
          onChange={setNewTeamName}
          placeholder="My team name"
          loading={loadingTeamName}
          disabled={!isTeamAdmin()}
          onBlur={async (e) => {
            if (e.target.value === activeTeam.name) return
            handleUpdateTeamName()
          }}
        />
      </div>

      <div className="mt-10">
        <h2 className="text-3xl">Team members</h2>
        <div className={['my-6 px-4 py-4 flex flex-col gap-6 bg-gray-900', loadingTeamWithMeta && 'bg-animated from-gray-900 to-gray-800'].join(' ')}>
          <div className="grid grid-cols-7 gap-5">
            <div className="col-span-3 font-bold text-sm">Email</div>
            <div className="font-bold text-sm">Role</div>
            <div className="col-span-2 font-bold text-sm">Added</div>
            <div className=" font-bold text-sm">Actions</div>

            {/* MEMBERS */}
            {Object.entries(activeTeam.members)?.map(([id, member]) => {
              return (
                <TeamMemberRow
                  key={id}
                  member={{ ...member, ...activeTeamWithMeta[id] }}
                  handleRemoveTeamMember={() => handleRemoveTeamMember(id)}
                  isTeamAdmin={isTeamAdmin()}
                />
              )
            })}

            {/* PENDING */}
            {Object.values(activeTeam?.pending || {})?.length > 0 &&
              isTeamAdmin() &&
              Object.entries(activeTeam?.pending || {})?.map(([id, member]) => {
                return (
                  <TeamMemberRow
                    key={id}
                    member={{ ...member, email: id }}
                    pending={true}
                    handleRemoveTeamMember={handleRemoveTeamMember}
                    isTeamAdmin={isTeamAdmin()}
                  />
                )
              })}
          </div>
        </div>
        {isTeamAdmin() &&
        <div className='flex items-center gap-6'>
          <Button
            text="Add new member"
            icon={faPlus}
            onClick={() => {
              if(exceededTeamMemberLimit) return setShowUpgradeModal(true)
              setShowAddTeamMemberModal(true)
            }}
            toolTip={exceededTeamMemberLimit ? <>Limit reached <FontAwesomeIcon icon={faBolt} className='text-yellow-500' /> Upgrade for more</> : ''}
          />
          {
            teamMemberLimit === -1 ?
              null // Skip show message if user has unlimited team members
              :
              <p className='text-sm text-gray-400'>You&apos;ve added {teamMemberRunningTotal} of {teamMemberLimit} team members</p>
          }
        </div>
        }

        <UpgradeModal
          open={showUpgradeModal}
          setOpen={setShowUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message='You&apos;ve reached your limit for team members.'
        />

        <ModalWrapper
          open={[showAddTeamMemberModal, setShowAddTeamMemberModal]}
          onHide={() => setNewTeamMember(newTeamMemberDefaults)}
          title="Add new team member"
          buttons={[
            {
              text: 'Add team member',
              onClick: handleAddTeamMember,
            },
          ]}
        >
          <div className="flex gap-4">
            <div className="flex-1 w-1/2">
              <Input
                label="Email address"
                type="email"
                value={newTeamMember.email}
                placeholder="john@email.com"
                autofocus={true}
                onChange={(value) => {
                  setNewTeamMember({ ...newTeamMember, email: value })
                }}
              />
            </div>
            <Select
              label="Role"
              css="w-1/2"
              value={newTeamMember.role}
              onChange={(choice) => {
                setNewTeamMember({ ...newTeamMember, role: choice })
              }}
              options={[
                ...Object.values(TeamMemberRoles).map((role) => {
                  return {
                    name: role.displayName,
                    value: role.name,
                  }
                }),
              ]}
            />
          </div>
        </ModalWrapper>
      </div>
    </DashboardWrapper>
  )
}

const TeamMemberRow = ({ member, pending = false, handleRemoveTeamMember = null, isTeamAdmin = false }) => {
  const [showContext, setShowContext] = useState(false)

  const contextMenuActions = {
    remove_team_member: () => handleRemoveTeamMember(member.email),
  }

  return (
    <>
      <div className="col-span-3">
        <span className={['text-sm', pending && 'italic text-gray-500'].join(' ')}>
          {member.email} {pending && '(pending)'}
        </span>
      </div>
      <div>
        <span className={['px-1 py-0.5 text-xs rounded bg-gray-800/50', TeamMemberRoles[member.role]?.css].join(' ')}>
          {TeamMemberRoles[member.role]?.displayName}
        </span>
      </div>
      <div className="col-span-2">
        <span className="text-sm">{member?.createdAt?._seconds ? format(new Date(member?.createdAt?._seconds * 1000), 'd MMM yy') : '-'}</span>
      </div>
      <div className="relative">
        <button
          onClick={(e) => setShowContext(e.nativeEvent)}
          className="px-2 h-8 rounded hover:bg-gray-800 disabled:pointer-events-none disabled:opacity-50"
          disabled={member?.role === TeamMemberRoles.team_admin.name || !isTeamAdmin}
        >
          <FontAwesomeIcon icon={faEllipsis} />
        </button>
        {showContext && (
          <ContextMenu
            openEvent={showContext}
            placement="top-9 left-0"
            onClose={() => setShowContext(false)}
            items={[
              {
                name: 'Remove ',
                value: 'remove_team_member',
              },
            ]}
            onClick={(choice) => contextMenuActions[choice]()}
          />
        )}
      </div>
    </>
  )
}

TeamMemberRow.propTypes = {
  member: PropTypes.object,
  pending: PropTypes.bool,
  handleRemoveTeamMember: PropTypes.func,
  isTeamAdmin: PropTypes.bool,
}

import { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import isValidDate from '../../utils/isValidDate.js'
import { RundownToken } from '../../axios.js'
import { ACCESS_WRITE } from '../../constants/rundownAccessStates.js'
import { floorSeconds, isBrowser24h } from '../../utils/formatTime.js'
import parseInTimezone from '../../utils/parseInTimezone.js'
import { formatInTimeZone } from 'date-fns-tz'

export default function TimeInput ({
  className = '',
  title,
  timezone,
  time,
  updateTime,
  ...props
}) {

  const [internalTime, setInternalTime] = useState('10:00 AM')
  const [valid, setValid] = useState(true)
  const focusRef = useRef(false)
  const touchedRef = useRef(false)
  const commitMeRef = useRef(false)
  const disabled = RundownToken.access !== ACCESS_WRITE
  const is24h = isBrowser24h()

  useEffect(() => {
    if (!time) return
    if (focusRef.current) return
    if (touchedRef.current) return
    const newTime = toInternal(time, timezone)
    if (newTime === internalTime) return
    setInternalTime(newTime)
  }, [time, timezone])


  function onChange (event) {
    setValid(isValidDate(fromInternal(event.target.value, timezone)))
    setInternalTime(event.target.value)
    touchedRef.current = true
    commitMeRef.current = true
  }

  function onKeydown (event) {
    if (event.key === 'Enter') {
      commitMeRef.current = true // force commit on blur
      event.target.blur()
    }
    if (event.key === 'Escape') {
      setInternalTime(toInternal(time, timezone))
      setValid(true)
      touchedRef.current = false
      commitMeRef.current = false // prevent commit on blur
      event.target.blur()
    }
  }

  function onFocus () {
    focusRef.current = true
  }

  function onBlur () {
    focusRef.current = false
    commitTime()
  }

  const commitTime = useCallback(() => {
    if (!commitMeRef.current) return
    commitMeRef.current = false
    if (!valid) return
    const oldInternalTime = toInternal(time, timezone)
    if (oldInternalTime !== internalTime) {
      const oldTime = Number(fromInternal(oldInternalTime, timezone))
      const newTime = Number(fromInternal(internalTime, timezone))
      updateTime({ time: new Date(newTime), diff: newTime - oldTime })
    }
    touchedRef.current = false
  }, [time, internalTime, valid, updateTime])

  return (
    <>
      <input
        data-label="time-input"
        className={[
          'cursor-text transition-colors px-1 text-gray-300 font-mono bg-transparent rounded-sm text-left focus:outline-none',
          (valid ? '' : 'ring-2 ring-red-500'),
          (disabled ? '' : 'hover:bg-white/20 focus:bg-gray-900 focus:ring'),
          className,
        ].join(' ')}
        style={{ width: is24h ? '110px' : '150px' }}
        title={title ? String(title) : ''}
        type="time"
        value={internalTime}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeydown}
        readOnly={disabled}
        step="2"
        {...props}
      />
    </>
  )
}

function toInternal (date, timezone) {
  if (!isValidDate(date)) return null
  const flooredTime = floorSeconds(date.getTime())
  if (timezone) return formatInTimeZone(flooredTime, timezone, 'HH:mm:ss')
  else return format(flooredTime, 'HH:mm:ss')
}

function fromInternal (timeStr, timezone) {
  const pattern = timeStr.split(':').length === 3 ? 'HH:mm:ss' : 'HH:mm'
  return parseInTimezone(timeStr, pattern, timezone)
}

TimeInput.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  timezone: PropTypes.string,
  time: PropTypes.instanceOf(Date).isRequired,
  /**
   * `updateTime` is called with an object
   * @type {object} payload
   * @type {Date} payload.time – new time (normized with today's calender date)
   * @type {number} payload.diff – normalized difference between old and new date in milliseconds
   */
  updateTime: PropTypes.func.isRequired,
}

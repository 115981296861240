import { getAuth, sendEmailVerification } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import Layout from '../components/Layout'
import { getUser } from '../firebase'

export default function Verify() {
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [message, setMessage] = useState('')

  const navigate = useNavigate()

  const handleSendVerificationEmail = async () => {
    setMessage('')
    setSending(true)

    const awaitedUser = await getUser()

    await sendEmailVerification(awaitedUser, { url: `${window.location.origin}/dashboard` })
    setMessage(`📧 Email sent to ${awaitedUser.email}! Please check your inbox.`)

    setTimeout(() => {
      setSent(true)
      setSending(false)
    }, [3000])
  }

  const handleLogoutUser = async () => {
    const auth = getAuth()
    await auth.signOut()
    return navigate('/login')
  }

  useEffect(()=>{
    if(!sent) {
      handleSendVerificationEmail()
    }
  }, [sent])

  return (
    <Layout title="Verify your email address">
      <>
        <p>We need to double-check you've entered the right address.</p>
        {message && <p className="text-sm text-blue-500">{message}</p>}
        <Button
          disabled={sending}
          loading={sending}
          onClick={handleSendVerificationEmail}
          colour={sent ? 'dark' : 'main'}
          text={sending ? 'Sending email...' : 'Re-send verification email'}
        />
        {sent && <Button onClick={() => navigate('/dashboard')} colour={sent ? 'main' : 'dark'} text="To the dashboard" />}
        <Button onClick={handleLogoutUser} text="Logout" css="bg-gray-900 text-white" />
      </>
    </Layout>
  )
}

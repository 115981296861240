import PropTypes from 'prop-types'
import CellItem from './CellItem.jsx'
import _keyBy from 'lodash/keyBy'

export default function CueCells ({
  cue,
  cells = [],
  columns = [],
  hiddenColumns = [],
  cellStyle,
  readonly,
  ...props
}) {
  const cellColMap = _keyBy(cells, 'columnId')

  const cellArray = columns
    .filter((column) => !hiddenColumns.includes(column.id))
    .map((col) => ({ col, cell: cellColMap[col.id] }))

  return cellArray.map(({ col, cell }) => (
    <CellItem
      key={`cell_${cue.id}_${col.id}`}
      cueId={cue.id}
      col={col}
      cell={cell}
      readonly={cue.locked || readonly}
      style={cellStyle}
      {...props}
    />
  ))
}

CueCells.propTypes = {
  cue: PropTypes.object.isRequired,
  cells: PropTypes.array,
  columns: PropTypes.array,
  hiddenColumns: PropTypes.array,
  cellStyle: PropTypes.object,
}

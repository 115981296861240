import ModalWrapper from './ModalWrapper.jsx'
import ShareableLinkAndAccessCode from './partials/ShareableLinkAndAccessCode.jsx'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'
import { useState, useEffect, useCallback } from 'react'
import { columnsAtom, rundownAtom } from '../../store/rundown.store.js'
import { generateRundownLinkWithSignature } from '../../utils/generateLinkWithSignature.js'
import { faExternalLink, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { updateRundown } from '../../firestore.js'
import * as OUTPUT_TYPES from '../../constants/outputConfigTypes.js'
import CurrentTimeOfDay from '../rundown/CurrentTimeOfDay.jsx'
import { CUE_BACKGROUND_COLORS } from '@rundown-studio/consts'
import Button from '../Button.jsx'

const OUTPUT_LAYOUTS = {
  [OUTPUT_TYPES.LIST]: (
    <>
      <div className='rounded-sm w-100 h-7 flex items-center justify-around gap-2'>
        <span className='text-[8px] ml-1 font-mono'>1</span>
        <span className='text-xs font-semibold  font-mono'>00:00</span>
        <span className='text-[8px]'>Title</span>
        <span className='grow'></span>
      </div>
      <div className='rounded-sm w-100 h-8 flex items-center justify-around gap-2'>
        <span className='text-[8px] ml-1 font-mono'>2</span>
        <span className='text-xs font-semibold  font-mono'>00:00</span>
        <span className='text-[8px]'>Title</span>
        <span className='grow'></span>
      </div>
    </>
  ),
  [OUTPUT_TYPES.SINGLE]: (
    <>
      <div className='rounded-sm w-100 h-2/3 text-sm font-semibold flex items-center justify-center font-mono'>00:00</div>
      <div className='rounded-sm w-100 h-1/3 text-[10px] flex items-center justify-center'>Current Cue title</div>
    </>
  ),
  [OUTPUT_TYPES.PROMPTER]: (
    <>
      <div className='rounded-sm w-100 pt-4 pl-2 h-1/3 text-[9px] flex items-center justify-center text-left'>Scrollable text for your teleprompter</div>
    </>
  ),
}

export default function RundownOutputModal({ rundownId, open, setOpen }) {
  const [columns] = useAtom(columnsAtom)
  const [rundown] = useAtom(rundownAtom)
  const [options, setOptions] = useState({})
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState()
  const [configOptions, setConfigOptions] = useState('')
  const [configOptionsObject, setConfigOptionsObject] = useState({})
  const [edited, setEdited] = useState(false)

  function handleShowColumn (column) {
    setOptions({ ...options, column })
  }

  async function handleSaveAdvancedConfig (text) {
    return await updateRundown(rundownId, { 'settings.outputConfig': text })
  }

  const handleObjectifyConfig = () => {
    const options = configOptions?.split('\n')
    const configObject = {}

    options.forEach(option => {
      const [key = '', value = ''] = option.split('=')
      if(!value) return null
      key.replace(/[^a-z0-9=,-]/gim,'')
      value.replace(/[^a-z0-9=,-]/gim,'')

      configObject[key] = value
    })
    setConfigOptionsObject(configObject)
  }

  const columnHeadingTextMap = {
    [OUTPUT_TYPES.LIST]: 'Choose which column is visible',
    [OUTPUT_TYPES.SINGLE]: 'No columns visible on this layout',
    [OUTPUT_TYPES.PROMPTER]: 'Choose a script column',
  }

  /**
   * Objectify the config options, while sanitizing them to be url-friendly
   * and force-build a new URL with these options
   */
  const applyConfigOptions = useCallback((async () => {
    setEdited(false)
    handleObjectifyConfig()
    handleSaveAdvancedConfig(configOptions)
  }), [configOptions])

  useEffect(() => { if(rundown.settings?.outputConfig) setConfigOptions(rundown.settings.outputConfig)}, [rundown.settings])

  const handleSetSelectedLayout = (key) => {
    const newConfigObject = configOptionsObject

    newConfigObject[OUTPUT_TYPES.LAYOUT] = key

    setConfigOptionsObject(newConfigObject)
    setConfigOptions(Object.entries(newConfigObject).map(([key, value])=> `${key}=${value}`).join('\n'))
    buildUrl()
  }

  const handleResetConfigToDefault = () => {
    setEdited(true)
    setConfigOptions(Object.values(OUTPUT_TYPES.OutputConfigTypes).map(({name, default: d})=> `${name}=${d}`).join('\n'))
  }

  /**
   * Build the shareable url from options and selectedLayout.
   * Is executed on onShow and every time the dependencies update.
   */
  const buildUrl = useCallback(async () => {
    setLoading(true)
    const newUrl = await generateRundownLinkWithSignature(rundownId, 'output', {...options, ...configOptionsObject})
    setUrl(newUrl)
    setLoading(false)
  }, [rundownId, options, configOptionsObject, configOptions])

  useEffect(() => { buildUrl() }, [options, configOptionsObject])

  return (
    <ModalWrapper
      title="Generate Output"
      open={[open, setOpen]}
      onShow={()=> {
        handleObjectifyConfig()
        handleShowColumn('')
      }}
    >
      <div className="grid grid-cols-2 gap-12">
        {/* LEFT SIDE */}
        <div>
          {/* Preview */}
          <p className="text-gray-400 font-light">Preview</p>
          <PreviewContents configOptionsObject={configOptionsObject} rundownTitle={rundown.name} visibleColumn={options.column} />
          {/* Cell editing */}
          <div className="space-y-2">
            {/* <p className="text-gray-400 font-light">Choose the layout</p> */}
            <div className='mt-6 flex w-full justify-between gap-4'>
              {Object.entries(OUTPUT_LAYOUTS).map(([key, layout]) => {
                const isSelectedLayout = configOptionsObject[OUTPUT_TYPES.LAYOUT] || OUTPUT_TYPES.LIST
                return (
                  <button
                    key={key}
                    disabled={loading}
                    className={[
                      'w-1/2 aspect-video bg-gray-950 rounded border',
                      (key === isSelectedLayout ? 'border-white hover:border-gray-500' : 'border-transparent'),
                    ].join(' ')}
                    onClick={() => handleSetSelectedLayout(key)}
                  >
                    <div className='p-1 gap-1 flex flex-col h-full'>
                      <div className='bg-gray-100/5 rounded-sm w-100 min-h-2'></div>
                      {layout}
                    </div>
                  </button>
                )
              })}
            </div>
          </div>
        </div>

        {/* RIGHT SIDE */}
        <div className='flex flex-col gap-4'>
          {/* Cell editing */}
          <div className="space-y-2">
            <p className="text-gray-400 font-light">
              {columnHeadingTextMap[configOptionsObject[OUTPUT_TYPES.LAYOUT]] || 'Select column'}
            </p>
            <select
              disabled={configOptionsObject[OUTPUT_TYPES.LAYOUT] === OUTPUT_TYPES.SINGLE}
              onChange={(e)=>handleShowColumn(e.target.value)}
              className={[
                'p-2 w-full bg-gray-800 rounded flex gap-2 cursor-pointer border hover:brightness-110 disabled:brightness-100 disabled:opacity-60 disabled:pointer-events-none focus:outline-none focus:ring',
                configOptionsObject[OUTPUT_TYPES.LAYOUT] === OUTPUT_TYPES.PROMPTER && ! options.column ? 'border-red-500' : 'border-transparent', // Visual reminder that a prompter column should be added
              ].join(' ')}
            >
              <option value="">None</option>
              {columns.map((col) => (
                <option key={col.id} value={col.id}>
                  {col.name || 'Unnamed Column'}
                </option>
              ))}
            </select>
          </div>

          {/* Advanced Configuration */}
          <div className=''>
            <p className="text-gray-400 font-light">
              Advanced configuration {' '}
              <a
                href='https://rundownstudio.app/docs/sharing/output/#advanced-output-config-options'
                className='underline whitespace-nowrap text-sm focus:outline-none focus:ring'
                target='_blank'
                rel="noreferrer"
              >See available options <FontAwesomeIcon icon={faExternalLink} /></a></p>
            <textarea
              className='mt-2 p-2 bg-gray-800 w-full text-xs font-mono rounded focus:outline-none focus:ring'
              value={configOptions}
              // eslint-disable-next-line quotes
              placeholder={`logo=on\ntime-of_day=on\n...`}
              onChange={(e) => {
                setEdited(true)
                setConfigOptions(e.target.value)
              }}
              rows={16}
            />
            <div className='flex justify-between'>
              <button
                className="text-xs text-gray-400 focus:outline-none focus:ring disabled:opacity-50"
                onClick={handleResetConfigToDefault}
              // disabled={fullAccess}
              >
              Reset to default
              </button>
              <Button
                text='Apply changes'
                size='small'
                className='px-2 py-1 text-xs font-base'
                onClick={applyConfigOptions}
                disabled={!edited}
                colour={edited ? 'main' : 'dark'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 space-y-2">
        <p className="text-gray-400 font-light">Shareable Link</p>
        <ShareableLinkAndAccessCode
          className=""
          url={url}
          loading={loading}
        />
      </div>
    </ModalWrapper>
  )
}

RundownOutputModal.propTypes = {
  rundownId: PropTypes.string.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}


const PreviewContents = ({ configOptionsObject, rundownTitle, visibleColumn }) => {

  const visibleCueCount = 4

  const styles = (option, defaultStyles) => {
    let opacity = 'opacity-100'
    if(configOptionsObject[option] === OUTPUT_TYPES.OFF) opacity = 'opacity-0 hidden'
    return [
      defaultStyles,
      opacity,
    ].join(' ')
  }

  const zoomLevel = Math.floor(parseFloat(configOptionsObject[OUTPUT_TYPES.ZOOM_LEVEL] || 1) * 100)

  return (
    <div
      className='mt-2 aspect-video rounded-sm'
      style={{
        backgroundColor: configOptionsObject[OUTPUT_TYPES.BACKGROUND_COLOR] || 'black',
      }}
    >
      <div className='relative h-full'>
        {/* Zoom level */}
        {(zoomLevel !== 100 && !isNaN(zoomLevel)) &&
          <div className={[
            'absolute right-0 p-1 text-xs bg-black z-10 border-l border-gray-800',
            (configOptionsObject[OUTPUT_TYPES.HEADER_POSITION] === OUTPUT_TYPES.BOTTOM ? 'top-0 border-b rounded-bl' : 'bottom-0 border-t rounded-tl'),
          ].join(' ')}><FontAwesomeIcon icon={faMagnifyingGlassPlus} />{' '}{zoomLevel}%</div>
        }
        <div className={[
          'relative p-2 flex h-full gap-1 text-[10px] text-gray-100 transition-transform',
          (configOptionsObject[OUTPUT_TYPES.MIRROR]?.includes(OUTPUT_TYPES.VERTICAL) && '-scale-y-100'),
          (configOptionsObject[OUTPUT_TYPES.MIRROR]?.includes(OUTPUT_TYPES.HORIZONTAL) && '-scale-x-100'),
          (configOptionsObject[OUTPUT_TYPES.HEADER_POSITION] === OUTPUT_TYPES.BOTTOM ? 'flex-col-reverse' : 'flex-col'),
        ].join(' ')}>
          {/* Heading */}
          <div className='flex gap-1 justify-end'>
            {/* Rundown title */}
            <div className={styles(OUTPUT_TYPES.RUNDOWN_TITLE, 'grow h-5 w-10 leading-5 whitespace-nowrap text-ellipsis overflow-hidden')}>{rundownTitle}</div>
            {/* Logo */}
            <div className={styles(OUTPUT_TYPES.LOGO, 'h-5 w-10 flex items-center justify-end ')}>
              {configOptionsObject[OUTPUT_TYPES.LOGO]?.includes('http') ?
                <img
                  className='w-6 max-h-4 text-center'
                  src={configOptionsObject[OUTPUT_TYPES.LOGO]}
                />
                :
                <img
                  className='w-4 text-center'
                  src={'/favicon.svg'}
                />
              }
            </div>
            {/* Time of day */}
            <div className={styles(OUTPUT_TYPES.TIME_OF_DAY, 'h-5 text-center whitespace-nowrap font-mono text-[8px] leading-[22px]')}><CurrentTimeOfDay /> </div>
            {/* Over/Under */}
            <div className={styles(OUTPUT_TYPES.OVER_UNDER, 'h-5 w-6 text-center leading-5 text-gray-600')}><span className='text-green-500'>+</span>/<span className='text-red-500'>-</span></div>
          </div>

          {/* Progress bar and last five seconds */}
          <div className='flex gap-1 justify-end items-end'>
            {/* Progress bar */}
            <div className={styles(OUTPUT_TYPES.PROGRESS_BAR, 'h-1 grow bg-gradient-to-r from-blue-500 to-red-600 rounded-[1px]')}></div>
            {/* Last five seconds */}
            <div className={styles(OUTPUT_TYPES.LAST_FIVE_SECONDS, 'h-1 w-10 flex gap-1')}>
              <div className='h-1 w-1 bg-red-500 rounded-[1px]'></div>
              <div className='h-1 w-1 bg-red-500 rounded-[1px]'></div>
              <div className='h-1 w-1 bg-red-500 rounded-[1px]'></div>
              <div className='h-1 w-1 bg-red-500 rounded-[1px]'></div>
              <div className='h-1 w-1 bg-red-500 rounded-[1px]'></div>
            </div>
          </div>

          <div className='mt-2 flex flex-col gap-1 h-full'>


            {/* List of cue */}
            {(configOptionsObject[OUTPUT_TYPES.LAYOUT] === OUTPUT_TYPES.LIST || !configOptionsObject[OUTPUT_TYPES.LAYOUT] )&&
              Array.from({length: visibleCueCount}).map((_, index)=>{
                if(index > 3) return null
                const displayIndex = index + parseInt(configOptionsObject[OUTPUT_TYPES.START_AT_CUE] - 1 || 0) + 1
                return (
                  <div key={index} className='flex h-full gap-1'>
                    <div
                      style={{
                        backgroundColor:
                          configOptionsObject[OUTPUT_TYPES.CUE_BACKGROUND_COLORS] === OUTPUT_TYPES.OFF || [0,3].includes(index) ?
                            'rgb(38 38 38 / 0.5)' // bg-gray-800/50
                            : CUE_BACKGROUND_COLORS[index + 1],
                      }}
                      className='px-2 grow rounded flex items-center gap-3'
                    >
                      <p className='font-mono'>{displayIndex}</p>
                      <div className='font-mono text-center'>
                        {configOptionsObject[OUTPUT_TYPES.START_TIMES] === OUTPUT_TYPES.OFF ? '' : <p className='text-[5px] opacity-50'>09:00</p>}
                        <p className='text-[8px] leading-[9px]'>10m</p>
                        {configOptionsObject[OUTPUT_TYPES.END_TIMES] === OUTPUT_TYPES.OFF ? '' : <p className='text-[5px] opacity-50'>09:10</p>}
                      </div>
                      <p className='text-[8px]'>Title for cue {displayIndex}</p>
                    </div>
                    {/* Column placeholder */}
                    {visibleColumn && <div
                      className='px-2 pt-1 w-20 rounded text-[6px]'
                      style={{
                        backgroundColor:
                          configOptionsObject[OUTPUT_TYPES.CUE_BACKGROUND_COLORS] === OUTPUT_TYPES.OFF || [0,3].includes(index) ?
                            'rgb(38 38 38 / 0.5)' // bg-gray-800/50
                            : CUE_BACKGROUND_COLORS[index + 1],
                      }}
                    >Column details for cue {displayIndex}</div>}
                  </div>
                )
              })
            }

            {/* Single cue */}
            {configOptionsObject[OUTPUT_TYPES.LAYOUT] === OUTPUT_TYPES.SINGLE &&
              <div  className='px-2 grow rounded flex flex-col items-center justify-end gap-3'>
                <div className='font-mono text-medium flex gap-2'>
                  {configOptionsObject[OUTPUT_TYPES.START_TIMES] === OUTPUT_TYPES.OFF ? '' : <p>09:00</p>}
                  {(configOptionsObject[OUTPUT_TYPES.START_TIMES] === OUTPUT_TYPES.OFF || configOptionsObject[OUTPUT_TYPES.END_TIMES] === OUTPUT_TYPES.OFF) ? '' : <p>→</p>}
                  {configOptionsObject[OUTPUT_TYPES.END_TIMES] === OUTPUT_TYPES.OFF ? '' : <p>09:10</p>}
                </div>
                <div className='my-0 font-mono text-5xl text-center'>
                  {configOptionsObject[OUTPUT_TYPES.LARGE_TIME_OF_DAY] === OUTPUT_TYPES.ON ? <p className='my-0 font-mono text-2xl opacity-70'><CurrentTimeOfDay /></p> : ''}
                  <p className='mt-0 font-mono'>1:20:00</p>
                </div>
                <p className='mt-0 mb-2 text-[12px]'>Cue title</p>
              </div>
            }

            {/* Prompter */}
            {configOptionsObject[OUTPUT_TYPES.LAYOUT] === OUTPUT_TYPES.PROMPTER &&
              <div  className='px-2 grow rounded flex flex-col items-center justify-start gap-3'>
                <div className='my-2 text-2xl text-left'>
                  <p className='my-0'>Welcome to the show! <br />Here is some scrollable text for your teleprompter.</p>
                </div>
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

PreviewContents.propTypes = {
  configOptionsObject: PropTypes.object,
  rundownTitle: PropTypes.string,
  visibleColumn: PropTypes.string,
}

/**
   * Parses custom components in a given text string and replaces them with standard HTML elements.
   *
   * This function currently handles the following custom components:
   * 1. `<p>`: Adds a whitespace to empty <p> tags to force proper line height.
   * 2. `<img-component>`: Replaces it with a standard `<img>` tag with the class "rounded".
   * 3. `<file-component>`: Replaces it with a standard `<a>` tag that opens the file link in a new tab.
   *
   * @param {string} text - The input text containing custom components to be parsed.
   * @return {string} - The parsed text with custom components replaced by standard HTML elements.
   */
export function simpleEditorContentParser (text) {
  if (!text) return ''
  let parsed = ''
  parsed = text.replace(/<p><\/p>/g, '<p>&nbsp;</p>')
  parsed = parsed.replace(/<img-component\s+src="([^"]+)"[^>]*><\/img-component>/g, '<img src="$1" class="tiptap-img-component">')
  parsed = parsed.replace(/<file-component\s+filename="([^"]+)".+?url="([^"]+)"[^>]*><\/file-component>/g, '<a href="$2" target="_blank" rel="noreferrer" class="tiptap-file-component">$1</a>')
  return parsed
}

import { useCallback } from 'react'
import { TeamMemberRoles } from '../../constants/teamRoles'
import { userAtom, userTeamAtom } from '../../store/user.store'
import { useAtomValue } from 'jotai'

export default function useIsTeamAdmin() {
  const user = useAtomValue(userAtom)
  const team = useAtomValue(userTeamAtom)

  const isTeamAdmin = useCallback(() => {
    return team?.members?.[user?.uid]?.role === TeamMemberRoles.team_admin.name
  }, [user, team])()

  return isTeamAdmin
}

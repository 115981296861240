import { atom } from 'jotai'

export const _userAtom = atom(null)

export const userAtom = atom(
  (get) => get(_userAtom),
  (get, set, updatedUser) => {
    set(_userAtom, updatedUser)
  },
)

export const _userTeamAtom = atom(null)

export const userTeamAtom = atom(
  (get) => get(_userTeamAtom),
  (get, set, updatedTeam) => {
    set(_userTeamAtom, updatedTeam)
  },
)

export const _userTeamsAtom = atom(null)

export const userTeamsAtom = atom(
  (get) => get(_userTeamsAtom),
  (get, set, teams) => {
    set(_userTeamsAtom, teams)
  },
)



const TEAM_ADMIN = 'team_admin'
const TEAM_BILLING = 'team_billing'
const TEAM_MEMBER = 'team_member'

export const TeamMemberRoles = {
  [TEAM_ADMIN]: {
    name: TEAM_ADMIN,
    displayName: 'Admin',
    css: 'text-red-500',
  },
  [TEAM_BILLING]: {
    name: TEAM_BILLING,
    displayName: 'Billing',
    css: 'text-green-500',
  },
  [TEAM_MEMBER]: {
    name: TEAM_MEMBER,
    displayName: 'Member',
    css: 'text-orange-500',
  },
}

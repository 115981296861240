import parse from 'date-fns/parse'
import addMilliseconds from 'date-fns/addMilliseconds'
import { getTimezoneOffset } from 'date-fns-tz'
import { floorSeconds } from './formatTime.js'

const localTz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone

/**
 * [parseInTimezone description]
 * @param  {string} timeStr  e.g. '2024-02-21 09:00:00'
 * @param  {string} pattern  e.g. 'yyyy-MM-dd HH:mm'
 * @param  {string} timezone
 * @param  {Date} [now]
 * @return {Date}
 */
export default function parseInTimezone (
  timeStr,
  pattern,
  timezone,
  now = floorSeconds(Date.now()),
) {
  // Parse date in browser environment
  // e.g. 2024-02-21 9:00 AM -> 2024-02-22T08:00:00.000Z (9 AM Europe/Berlin)
  const localTime = parse(timeStr, pattern, now)

  // Convert to UTC
  // e.g. 2024-02-22T08:00:00.000Z (9 AM Europe/Berlin) -> 2024-02-22T09:00:00.000Z (9 AM UTC)
  const localOffset = getTimezoneOffset(localTz, localTime)
  const utcTime = addMilliseconds(localTime, localOffset)

  // Convert to target timezone
  // e.g. 2024-02-22T09:00:00.000Z (9 AM UTC) -> 2024-02-22T17:00:00.000Z (9 AM America/Los_Angeles)
  const targetOffset = getTimezoneOffset(timezone || localTz, utcTime)
  const targetTime = addMilliseconds(utcTime, -targetOffset)

  return targetTime
}

export const TIME_OF_DAY = 'time-of-day'
export const PROGRESS_BAR = 'progress-bar'
export const PROGRESS_BAR_HEIGHT = 'progress-bar-height'
export const LAST_FIVE_SECONDS = 'last-five-seconds'
export const OVER_UNDER = 'over-under'
export const LOGO = 'logo'
export const MIRROR = 'mirror'
export const RUNDOWN_TITLE = 'rundown-title'
export const ZOOM_LEVEL = 'zoom'
export const CUE_BACKGROUND_COLORS = 'cue-background-colors'
export const START_END_TIMES = 'start-end-times'
export const START_TIMES = 'start-times'
export const END_TIMES = 'end-times'
export const START_AT_CUE = 'start-at-cue'
export const HEADER_POSITION = 'header-position'
export const LARGE_TIME_OF_DAY = 'large-time-of-day'
export const BACKGROUND_COLOR = 'background-color'
export const NEXT_CUE = 'next-cue'
export const LAYOUT = 'layout'

export const ON = 'on'
export const OFF = 'off'
export const VERTICAL = 'vertical'
export const HORIZONTAL = 'horizontal'
export const TOP = 'top'
export const BOTTOM = 'bottom'
export const SMALL = 'small'
export const MEDIUM = 'medium'
export const LARGE = 'large'
export const BLACK = 'black'

export const LIST = 'list'
export const SINGLE = 'single'
export const PROMPTER = 'prompter'

export const OutputConfigTypes = {
  [RUNDOWN_TITLE]: {
    name: RUNDOWN_TITLE,
    default: ON,
  },
  [LOGO]: {
    name: LOGO,
    default: ON,
  },
  [TIME_OF_DAY]: {
    name: TIME_OF_DAY,
    default: ON,
  },
  [OVER_UNDER]: {
    name: OVER_UNDER,
    default: ON,
  },
  [PROGRESS_BAR]: {
    name: PROGRESS_BAR,
    default: ON,
  },
  // [PROGRESS_BAR_HEIGHT]: {
  //   name: PROGRESS_BAR_HEIGHT,
  //   default: SMALL,
  // },
  [LAST_FIVE_SECONDS]: {
    name: LAST_FIVE_SECONDS,
    default: ON,
  },
  [MIRROR]: {
    name: MIRROR,
    default: OFF,
  },
  // [ZOOM_LEVEL]: {
  //   name: ZOOM_LEVEL,
  //   default: 1,
  // },
  [CUE_BACKGROUND_COLORS]: {
    name: CUE_BACKGROUND_COLORS,
    default: ON,
  },
  // [START_END_TIMES]: {
  //   name: START_END_TIMES,
  //   default: ON,
  // },
  [START_TIMES]: {
    name: START_TIMES,
    default: OFF,
  },
  [END_TIMES]: {
    name: END_TIMES,
    default: OFF,
  },
  // [START_AT_CUE]: {
  //   name: START_AT_CUE,
  //   default: '',
  // },
  [HEADER_POSITION]: {
    name: HEADER_POSITION,
    default: TOP,
  },
  [BACKGROUND_COLOR]: {
    name: BACKGROUND_COLOR,
    default: BLACK,
  },
  [LARGE_TIME_OF_DAY]: {
    name: LARGE_TIME_OF_DAY,
    default: OFF,
  },
  [NEXT_CUE]: {
    name: NEXT_CUE,
    default: OFF,
  },
  [LAYOUT]: {
    name: LAYOUT,
    default: LIST,
  },
}

import { faWarning, faTimes, faCircleCheck, faBullhorn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai'
import { createPortal } from 'react-dom'
import { toastAtom } from '../../store/toast.store.js'
import PropTypes from 'prop-types'
import { INFO, SUCCESS, WARN, FAIL } from '../../utils/toastTypes.js'

const typeIconMap = {
  [INFO]: faBullhorn,
  [SUCCESS]: faCircleCheck,
  [WARN]: faWarning,
  [FAIL]: faWarning,
}

function Portal({ children }) {
  return createPortal(children, document.getElementById('toast-root'))
}

export default function ToastWrapper () {
  const [toasts] = useAtom(toastAtom)
  return (
    <Portal>
      <div className="fixed left-10 bottom-10 z-[2000]">
        <div className="flex flex-col justify-end space-y-2 h-full">
          {Object.entries(toasts).map(([id, toast]) => (
            <ToastInternals key={id} toast={toast} />
          ))}
        </div>
      </div>
    </Portal>
  )
}

const ToastInternals = ({ toast }) => {
  return (
    <div className="relative p-3 bg-gray-800 w-[24rem] rounded shadow transition-all">
      <div className="flex items-center gap-2">
        <div
          className={[
            'flex-none w-10 text-center text-xl',
            toast.type === INFO && 'text-sky-500',
            toast.type === SUCCESS && 'text-green-500',
            toast.type === FAIL && 'text-red-500',
            toast.type === WARN && 'text-orange-500',
          ].join(' ')}
        >
          <FontAwesomeIcon icon={typeIconMap[toast.type]} />
        </div>
        <div className="flex-1">
          <p className="text-lg font-semibold">{toast.title}</p>
          <p className="text-sm text-gray-500">{toast.body}</p>
        </div>
        { toast.dismissible ? (
          <button
            className="flex-none w-7 h-7 rounded text-gray-600 hover:text-gray-400 hover:bg-gray-900"
            onClick={toast.dismiss}
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </button>
        ) : '' }
      </div>
      { toast.duration === undefined || toast.duration > 0 ? (
        <ProgressBarCss duration={toast.duration} />
      ) : '' }
    </div>
  )
}

ToastInternals.propTypes = {
  toast: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.oneOf([INFO, SUCCESS, WARN, FAIL]).isRequired,
    body: PropTypes.string,
    duration: PropTypes.number,
    dismissible: PropTypes.bool,
    dismiss: PropTypes.func,
    visible: PropTypes.func,
  }),
}

export const ProgressBarCss = ({ duration = 5000 }) => {
  return (
    <div className="relative h-1 mt-2 rounded-sm overflow-hidden">
      <span className="absolute inset-0 bg-gradient-to-r from-blue-500 to-red-600"></span>
      <span
        className="absolute inset-0 -right-1 bg-black/70 border-r-2"
        style={{
          animationName: 'translateInFromLeft',
          animationDuration: `${duration / 1000}s`,
          animationTimingFunction: 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
        }}
      ></span>
    </div>
  )
}

ProgressBarCss.propTypes = {
  duration: PropTypes.number,
}

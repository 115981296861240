import { useNavigate } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import Layout from '../components/Layout'
import { useEffect } from 'react'

export default function Logout() {
  // const [error, setError] = useState('')

  const navigate = useNavigate()

  const handleLogout = async () => {
    const auth = getAuth()

    await auth.signOut()

    const params = new URLSearchParams(window.location.search)
    const next = params.get('next')

    if (next) return navigate(`/login?next=${next}`)

    // return navigate('/login')
  }

  useEffect(() => {
    handleLogout()
  }, [])
  return <Layout title="logging you out..."></Layout>
}

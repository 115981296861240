import PropTypes from 'prop-types'
import { LargeTimeOfDay } from './RundownOutputHeroCue'
import { CELL_SELECT_COLORS } from '@rundown-studio/consts'
import _indexOf from 'lodash/indexOf'
import ScrollIntoView from '../ScrollIntoView'

export default function RundownOutputPrompter ({ rundown, cues, cells, runner, moment, visibleColumn }) {
  const currentCue = runner.timesnap?.cueId ? cues[moment?.cueId] : null

  // Partial State: When rundown is not started
  if (!currentCue) return (
    <div className='h-full flex flex-col items-center justify-center'>
      <LargeTimeOfDay className='opacity-100' timezone={rundown.timezone} />
      <p className='text-xl opacity-70'>Waiting for the rundown to start</p>
    </div>
  )

  // Partial State: When user did not select a column for the prompter
  if(!visibleColumn) return (
    <div className='h-full flex flex-col items-center justify-center'>
      <p className='text-xl opacity-70'>No script column selected</p>
    </div>
  )

  const cell = cells?.[currentCue.id]

  // Partial State: No cell exists for the currently active cue
  if(!cell) return null

  // Ideal State: A column has been selected
  return (
    <div className="relative ProseMirror h-full">
      {/* If the user has scrolled down, scroll them back to the top */}
      {/* KEY ensures that the ScrollIntoView component is re-rendered */}
      <ScrollIntoView key={cell.id} />

      {visibleColumn?.type === 'select' &&
        <div
          className='pt-10'
        >
          <div
            style={{
              backgroundColor: CELL_SELECT_COLORS[_indexOf(visibleColumn.options, cell?.content.selected)],
            }}
            className={[
              'text-6xl w-min whitespace-nowrap rounded-full px-5 py-4 text-center mx-auto',
            ].join(' ')}
            dangerouslySetInnerHTML={{ __html: cell?.content?.selected }}></div>
        </div>
      }

      {visibleColumn?.type === 'richtext' &&
      <div className='pt-10 px-10 pb-20 text-6xl w-full leading-[1.4]' dangerouslySetInnerHTML={{ __html: cell?.content?.text }}></div>
      }
    </div>
  )
}

RundownOutputPrompter.propTypes = {
  rundown: PropTypes.object,
  cues: PropTypes.object,
  cells: PropTypes.object,
  moment: PropTypes.object,
  runner: PropTypes.object,
  visibleColumn: PropTypes.object,
}

import { faBackwardStep, faForwardStep, faPause, faPlay, faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { formatCountdown } from '../../utils/formatTime.js'
import PropTypes from 'prop-types'
import SeekableProgressBar from './SeekableProgressBar'
import { RundownToken } from '../../axios'
import { ACCESS_WRITE } from '../../constants/rundownAccessStates'
import Button from '../Button.jsx'

export default function TimelineTransportControls ({
  moment,
  loading,
  nextCueId,
  onNext,
  onPause,
  onPlay,
  onEnd,
  onAddRemoveTime,
  onResetRunner,
  runnerEnded = false,
  handleJumpInTime,
  readonly,
}) {
  // Keybaord shortcuts
  useEffect(() => {
    const handleKeyDown = (event) => {

      // IF rundown is not running, ignore these keyboard shortcurts
      if(!moment.running) return

      if(RundownToken.access !== ACCESS_WRITE) return null
      const targetElement = event.target.tagName.toLowerCase()

      // Check if we are in an input or a tiptap editor
      if (targetElement === 'input' || targetElement === 'textarea' || hasParentWithTiptapId(event.target)) {
        return
      }

      if (event.code === 'Space') {
        event.preventDefault()
        if(moment.running && nextCueId) onNext()
        else if(moment.running && !nextCueId) onEnd()
      }
      // Removed for now
      // if (event.code === 'Enter') {
      //   event.preventDefault()
      //   if(moment.running) onPause()
      //   if(!moment.running) onPlay()
      // }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onNext, onPause, onPlay, moment.running])

  return (
    <div className="flex flex-col gap-3">
      {/* TIMES AND BUTTONS */}
      <div className="w-100 flex items-center gap-3">
        <div className="w-[22px] h-100 text-right flex-shrink-0">
          {moment.running ?
            <span className='h-[12px] w-[12px] rounded-full inline-block bg-red-500 animate-pulse'></span>
            :
            <FontAwesomeIcon icon={faPause} className='text-gray-400' />
          }

        </div>
        <div className="px-2 w-[130px] flex flex-col items-start">
          <p className='text-gray-500 font-semibold whitespace-pre text-[10px]'>Current cue</p>
          <p className={['font-mono ', moment.left < 0 && 'text-red-500'].join(' ')}>
            {formatCountdown(moment.left)}
          </p>
        </div>
        {RundownToken.access === ACCESS_WRITE && (
          <div className="w-full flex gap-3">

            {/* REMOVE TIME */}
            <Button
              text='-1m'
              toolTip='Remove 1 minute'
              colour='dark'
              className='grow-0 shrink-0'
              onClick={()=>onAddRemoveTime(-60000)}
              loading={loading}
              disabled={!moment.running}
              secondaryMenu={true}
              secondaryMenuItems={[
                {
                  name: '-30m',
                  value: -1800000,
                },
                {
                  name: '-10m',
                  value: -600000,
                },
                {
                  name: '-5m',
                  value: -300000,
                },
                {
                  name: '-1m',
                  value: -60000,
                },
                {
                  name: '-30s',
                  value: -30000,
                },
                {
                  name: '-10s',
                  value: -10000,
                },
                {
                  name: '-1s',
                  value: -1000,
                },
              ]}
              secondaryMenuOnClick={(amount)=> onAddRemoveTime(amount)}
            />

            {
              runnerEnded ?
                <>
                  {/* RESET RUNNER */}
                  <div className='grow'>
                    <Button
                      onClick={onResetRunner}
                      colour='red'
                      className="w-full rounded h-[38px] bg-gray-700 hover:brightness-110"
                      loading={loading}
                      disabled={loading}
                      text=''
                      icon={faBackwardStep}
                      toolTip='Reset rundown timing'
                    />
                  </div>
                </>
                :
                <>
                  {/* PAUSE RUNNER */}
                  <div className='grow'>
                    <Button
                      onClick={moment.running ? onPause : onPlay }
                      colour={moment.running ? 'dark' : 'red'}
                      className="w-full rounded h-[38px]"
                      loading={loading}
                      disabled={loading}
                      icon={moment.running ? faPause : faPlay}
                      toolTip={moment.running ? 'Pause rundown' : 'Start rundown'}
                    />
                  </div>
                </>
            }

            {/* NEXT CUE */}
            <div className='grow'>
              <Button
                icon={faForwardStep}
                text='Next cue'
                onClick={onNext}
                colour={moment.running && nextCueId ? 'red' : 'dark'}
                className="w-full h-[38px]"
                loading={loading}
                disabled={loading || (!moment.running || !nextCueId)}
              />
            </div>

            {/* END SHOW */}
            <div className='grow'>
              <Button
                icon={faStop}
                text=''
                onClick={onEnd}
                colour={moment.running && !nextCueId ? 'red' : 'dark'}
                className="w-full rounded h-[38px]"
                loading={loading}
                disabled={loading || !(moment.running && !nextCueId)}
                toolTip='End rundown'
              />
            </div>

            {/* ADD TIME */}
            <Button
              text='+1m'
              toolTip='Add 1 minute'
              colour='dark'
              className='grow-0 shrink-0'
              onClick={()=>onAddRemoveTime(+60000)}
              loading={loading}
              disabled={!moment.running}
              secondaryMenu={true}
              secondaryMenuItems={[
                {
                  name: '+30m',
                  value: 1800000,
                },
                {
                  name: '+10m',
                  value: 600000,
                },
                {
                  name: '+5m',
                  value: 300000,
                },
                {
                  name: '+1m',
                  value: 60000,
                },
                {
                  name: '+30s',
                  value: 30000,
                },
                {
                  name: '+10s',
                  value: 10000,
                },
                {
                  name: '+1s',
                  value: 1000,
                },
              ]}
              secondaryMenuOnClick={(amount)=> onAddRemoveTime(amount)}
            />
          </div>
        )}
      </div>
      {/* TIMELINE */}
      <div className="flex gap-3">
        <div className="w-[22px] flex-shrink-0"></div>

        <SeekableProgressBar moment={moment} handleJumpInTime={handleJumpInTime} readonly={readonly} />
      </div>
    </div>
  )
}

TimelineTransportControls.propTypes = {
  moment: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  nextCueId: PropTypes.string,
  onNext: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired,
  onPlay: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  onAddRemoveTime: PropTypes.func.isRequired,
  onResetRunner: PropTypes.func.isRequired,
  runnerEnded: PropTypes.bool,
  handleJumpInTime: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
}

function hasParentWithTiptapId (element) {
  let currentElement = element
  while (currentElement.parentElement) {
    currentElement = currentElement.parentElement
    if (currentElement.id === 'tiptap') {
      return true
    }
  }
  return false
}

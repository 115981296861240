import { useState } from 'react'
import PropTypes from 'prop-types'
import { formatDuration, formatDurationHuman, hmsToMilliseconds, millisecondsToHms } from '../../utils/formatTime'
import EditableHms from './EditableHms.jsx'

export default function EditableDurationWrapper({
  className,
  duration,
  updateDuration,
  disabled = false,
  humanize = true,
}) {
  const [editing, setEditing] = useState(false)
  const [loading, setLoading] = useState(false)

  async function handleHmsUpdate (newDuration) {
    if(!editing) return
    setEditing(false)
    if (duration === newDuration) return
    setLoading(true)
    await updateDuration(newDuration)
    setLoading(false)
  }

  function handleKeyDown (event) {
    if (event.key === 'Escape') setEditing(false)
  }

  if (disabled) {
    return (
      <p className={['text-right', className].join(' ')}>
        {humanize ? formatDurationHuman(duration) : formatDuration(duration)}
      </p>
    )
  }

  if (editing) {
    return (
      <EditableHms
        className={className}
        hms={millisecondsToHms(duration)}
        onUpdate={(hms) => handleHmsUpdate(hmsToMilliseconds(hms))}
        onKeyDown={handleKeyDown}
      />
    )
  }

  return (
    <button
      className={[
        'w-full px-2 hover:bg-white/10 text-right cursor-text rounded whitespace-nowrap focus:outline-none focus:ring',
        (loading ? 'bg-animated from-transparent to-white/20 pointer-events-none' : ''),
        className,
      ].join(' ')}
      disabled={loading}
      onClick={() => setEditing(true)}
    >
      <span>{humanize ? formatDurationHuman(duration) : formatDuration(duration)}</span>
    </button>
  )
}

EditableDurationWrapper.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number.isRequired,
  updateDuration: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  humanize: PropTypes.bool,
}

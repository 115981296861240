import { useState, useEffect, useMemo } from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import RundownNavbar from '../../components/rundown/RundownNavbar.jsx'
import TimelineTransportControls from '../../components/rundown/TimelineTransportControls.jsx'
import ColumnHeaders from '../../components/rundown/Columns.jsx'
import Cues from '../../components/rundown/Cues.jsx'
import { endRunnerTimesnap, pauseRunnerTimesnap, resetRundownRunner, startRunnerTimesnap, updateRundownCue, updateRunner } from '../../firestore.js'
import { ACCESS_WRITE } from '../../constants/rundownAccessStates.js'
import { RundownToken } from '../../axios.js'
import TimeAndOverUnder from '../../components/rundown/TimeAndOverUnder.jsx'
import { movePlayhead } from '../../utils/movePlayhead.js'
import { Helmet } from 'react-helmet'

export default function RundownMain () {
  const {
    rundown,
    runner,
    moment,
    cues,
    cells,
    columns,
    timestamps,
    plan,
    event,
  } = useOutletContext()
  const [runnerLoading, setRunnerLoading] = useState(false)
  const [hiddenColumns, setHiddenColumns] = useState([])
  const [searchParams] = useSearchParams()
  const writeAccess = RundownToken.access === ACCESS_WRITE

  async function onPlay() {
    setRunnerLoading(true)
    await startRunnerTimesnap(rundown.id, rundown.runnerId)
    setRunnerLoading(false)
  }

  async function onPause() {
    setRunnerLoading(true)
    await pauseRunnerTimesnap(rundown.id, rundown.runnerId)
    setRunnerLoading(false)
  }

  async function onNext() {
    setRunnerLoading(true)
    await startRunnerTimesnap(rundown.id, rundown.runnerId, runner.nextCueId)
    setRunnerLoading(false)
  }

  async function onResetRunner() {
    setRunnerLoading(true)
    await resetRundownRunner(rundown.id, rundown.id)
    setRunnerLoading(false)
  }

  async function onEndRunner() {
    setRunnerLoading(true)
    await endRunnerTimesnap(rundown.id, rundown.runnerId)
    setRunnerLoading(false)
  }

  async function handleJumpInTime(delta) {
    setRunnerLoading(true)
    const newRunner = movePlayhead(runner, delta)
    await updateRunner(rundown.id, rundown.runnerId, {timesnap: newRunner.timesnap})
    setRunnerLoading(false)
  }

  async function onAddRemoveTime(amount) {
    setRunnerLoading(true)
    const newDuration = cues[runner.timesnap.cueId].duration + amount
    await updateRundownCue(rundown.id, runner.timesnap.cueId, {
      duration: Math.max(newDuration, 0),
    })
    setRunnerLoading(false)
  }

  useEffect(() => {
    let colIds = (searchParams.get('hiddenColumns') || '').split(',').filter(Boolean)
    setHiddenColumns(colIds)
  }, [searchParams])

  const RundownNavbarMemo = useMemo(() => <RundownNavbar rundown={rundown} event={event} />, [rundown])

  return (
    <main
      className="relative text-white"
      data-label="layout-rundown"
    >
      <Helmet>
        <title>{rundown?.name} | Rundown Studio</title>
      </Helmet>
      <div className="min-w-screen w-max min-w-[100vw] flex flex-col">
        <div className="sticky top-0 left-0 right-0 z-[999]">
          {RundownNavbarMemo}
          <section className="h-[100px] bg-black/90 backdrop-blur px-2 py-2 flex items-end gap-4">
            <div className='w-[610px]'>
              <TimelineTransportControls
                moment={moment}
                timestamps={timestamps}
                loading={runnerLoading}
                nextCueId={runner.nextCueId}
                onNext={onNext}
                onPause={onPause}
                onPlay={onPlay}
                onEnd={onEndRunner}
                onAddRemoveTime={onAddRemoveTime}
                onResetRunner={onResetRunner}
                runnerEnded={runner.timesnap.ended}
                handleJumpInTime={handleJumpInTime}
                readonly={!writeAccess}
              />
            </div>
            <div className='h-[100px] ml-5 flex flex-col justify-between'>
              <TimeAndOverUnder
                timestamps={timestamps}
                timezone={rundown.timezone}
              />
              <ColumnHeaders columns={columns} hiddenColumns={hiddenColumns} columnsOrder={rundown.columns} plan={plan}/>
            </div>
          </section>
        </div>
        <Cues
          cues={cues}
          cells={cells}
          columns={columns}
          timestamps={timestamps}
          runner={runner}
          timezone={rundown.timezone}
          hiddenColumns={hiddenColumns}
          readonly={!writeAccess}
          plan={plan}
        />
      </div>
    </main>
  )
}

// RundownMain.propTypes = {
//   rundown: PropTypes.object.isRequired,
//   runner: PropTypes.object.isRequired,
//   moment: PropTypes.object.isRequired,
//   cues: PropTypes.array.isRequired,
//   cells: PropTypes.array.isRequired,
//   columns: PropTypes.array.isRequired,
//   timestamps: PropTypes.object.isRequired,
//   setRunner: PropTypes.func.isRequired,
// }

import PropTypes from 'prop-types'
import { useCurrentEditor } from '@tiptap/react'
import { useEffect } from 'react'

/**
 * Handles the 'focus' event on the `eventEmitter`
 * Accepts `location` values from https://tiptap.dev/docs/editor/api/commands/focus
 */
export default function TipTapFocusHandler ({ eventEmitter }) {
  const { editor } = useCurrentEditor()

  useEffect(() => {
    if (!eventEmitter) return
    eventEmitter.on('focus', onFocus)
    return () => eventEmitter.off('focus', onFocus)
  }, [eventEmitter])

  function onFocus (location = 'end') {
    editor.commands.focus(location)
  }

  // This component does not render anything to the DOM.
  return null
}

TipTapFocusHandler.propTypes = {
  eventEmitter: PropTypes.object,
}

import { useCallback, useRef, useEffect } from 'react'
import textfit from 'textfit'
import PropTypes from 'prop-types'


export default function RundownTextFitWrapper ({ height = '1vh', className, multiLine = false, children }) {

  const visibleElementRef = useRef()
  const hiddenElementRef = useRef()

  const fit = useCallback(() => {
    // Fit elapsed time
    const elapsedEl = hiddenElementRef.current
    if(elapsedEl) {
      elapsedEl.innerHTML = visibleElementRef.current.innerHTML
      textfit(elapsedEl, {maxFontSize: 999, multiLine })
      visibleElementRef.current.style.fontSize = elapsedEl.querySelector('span.textFitted')?.style.fontSize
    }

  }, [hiddenElementRef, visibleElementRef])

  useEffect(()=>{
    fit()
    window.addEventListener('resize', fit)

    return () => document.removeEventListener('resize', fit)
  }, [children, fit])

  return (
    <div
      className={[
        'relative flex flex-col items-center justify-center text-center leading-none',
        className,
      ].join(' ')}
      style={{ height }}
    >
      <div
        ref={hiddenElementRef}
        className='w-full flex items-center justify-center opacity-0'
        style={{ height }}
      >
        {/* Holding DIV needed incase the children disappear */}
        <div>
          {children}
        </div>
      </div>
      <div
        ref={visibleElementRef}
        className='w-full flex items-center justify-center absolute top-0 bottom-0'
        style={{ height }}
      >
        {/* Holding DIV needed incase the children disappear */}
        <div>
          {children}
        </div>
      </div>
    </div>
  )
}

RundownTextFitWrapper.propTypes = {
  height: PropTypes.string,
  className: PropTypes.string,
  multiLine: PropTypes.bool,
  children: PropTypes.any,
}
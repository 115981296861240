import { atom } from 'jotai'

export const eventListAtom = atom([])
export const removeEventAtom = atom(
  null, // write-only atom
  (get, set, id) => {
    const list = get(eventListAtom)
    set(eventListAtom, list.filter((item) => item.id !== id))
  },
)
export const updateEventAtom = atom(
  null, // write-only atom
  (get, set, event) => {
    const list = get(eventListAtom)
    set(
      eventListAtom,
      list.map((item) => {
        if (item.id !== event.id) return item
        return { ...event }
      }),
    )
  },
)
export const addEventAtom = atom(
  null, // write-only atom
  (get, set, event) => {
    const list = get(eventListAtom)
    set(eventListAtom, [...list, event])
  },
)

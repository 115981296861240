import { format } from 'date-fns'
import millisecondsToDhms from './millisecondsToDhms.js'
import _padStart from 'lodash/padStart'

export function formatDuration (milliseconds) {
  const zero = new Date().setHours(0, 0, 0, 0)

  const stringFormat = milliseconds > 3599999 || milliseconds < -3599999 ? 'hh:mm:ss' : 'mm:ss'

  return format(zero + Math.abs(milliseconds), stringFormat)
}

export function formatCountdown (milliseconds) {
  const dhms = millisecondsToDhms(milliseconds, { ceil: true })
  const toDigit = (num, digits) => _padStart(num, digits, '0')
  let output = toDigit(dhms.minutes, 2) + ':' + toDigit(dhms.seconds, 2)
  if (dhms.days) output = dhms.days + ':' + toDigit(dhms.hours, 2) + ':' + output
  else if (dhms.hours) output = dhms.hours + ':' + output
  return output
}

const dropZero = (milliseconds, hms, string = 'h') => {
  const amount = millisecondsToHms(milliseconds)[hms]
  if (amount === 0) return ''
  return ` ${amount}${string}`
}
export function formatDurationHuman (milliseconds) {
  const absMs = Math.abs(milliseconds)
  if (absMs > 3599999) {
    return `${dropZero(absMs, 'hours', 'h')}${dropZero(absMs, 'minutes', 'm')}${dropZero(absMs, 'seconds', 's')}`
  } else if (absMs > 59999) {
    return `${dropZero(absMs, 'minutes', 'm')}${dropZero(absMs, 'seconds', 's')}`
  } else if (absMs > 0) {
    return `${dropZero(absMs, 'seconds', 's')}`
  } else {
    return '0s'
  }
}

/**
 * Foramt time of day using browser language
 * @param  {Date} date
 * @param  {boolean} [options.secondsOptional=false]
 * @param  {boolean} [options.hour12]
 * @param  {string} [options.timezone]
 * @param  {boolean} [options.omitMinutes]
 * @return {string}
 */
export function formatTimeOfDay (date, {
  secondsOptional = false,
  hour12 = undefined,
  timezone = undefined,
  omitMinutes = false,
} = {}) {
  if (!date) return ''
  const options = {}
  const hasSeconds = date.getSeconds() !== 0
  options.timeStyle = secondsOptional && !hasSeconds ? 'short' : 'medium'
  if (hour12 !== undefined) options.hour12 = hour12
  if (timezone) options.timeZone = timezone
  if (omitMinutes) options.timeStyle = 'short'
  const language = !navigator.language || navigator.language === 'C' ? 'en-US' : navigator.language
  return new Intl.DateTimeFormat(language, options).format(date)
}

/**
 * Format calendar date using the browser language
 * @param  {Date} date
 * @param  {string} [timezone]
 * @return {string}
 */
export function formatCalendarDate (date, timezone = undefined) {
  if (!date) return ''
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
  if (timezone) options.timeZone = timezone
  return new Intl.DateTimeFormat(navigator.language, options).format(date)
}

export function millisecondsToHms (ms = 0) {
  return {
    hours: Math.floor(ms / 3600000) || 0,
    minutes: Math.floor((ms % 3600000) / 60000) || 0,
    seconds: Math.floor((ms % 60000) / 1000) || 0,
    decimals: Math.floor((ms % 1000) / 100) || 0,
  }
}

export function hmsToMilliseconds ({ hours = 0, minutes = 0, seconds = 0 } = {}) {
  return hours * 3600000 + minutes * 60000 + seconds * 1000
}

export function formatString (date) {
  if (!date) return ''
  const formatStr = date.getSeconds() !== 0 ? 'HH:mm:ss' : 'HH:mm'
  return format(date, formatStr)
}

/**
 * Round down a time, given in milliseconds, to the nearest second
 * @param  {number} ms [a timestamp represented as milliseconds]
 * @return {number}
 */
export function floorSeconds (ms) {
  return Math.floor(ms / 1000) * 1000
}

export function isBrowser24h () {
  const formattedTime = new Intl.DateTimeFormat(navigator.language, { hour: 'numeric' }).format(0)
  return !(/AM|PM/i.test(formattedTime))
}

import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { useMiddleware, awaitUser, requireAuth, dashboardRedirect } from './middleware.js'
import { injectAddToast } from '../firestore.js'
import { toastAtom } from '../store/toast.store.js'
import { useSetAtom } from 'jotai'
import Login from '../routes/login'
import Signup from '../routes/signup'
import Verify from '../routes/verify'
import Create from '../routes/create'
import Profile from '../components/dashboard/Profile'
import ToastWrapper from '../components/toast/ToastWrapper.jsx'
import Playground from '../routes/playground'
import RundownParent from '../routes/rundown/parent.jsx'
import RundownMain from '../routes/rundown/rundown-main.jsx'
import RundownOutput from '../routes/rundown/rundown-output.jsx'
import Code from '../routes/code.jsx'
import Team from '../components/dashboard/Team.jsx'
import AcceptInvite from '../routes/accept-invite.jsx'
import Logout from '../routes/logout.jsx'
import Reset from '../routes/reset.jsx'
import Checkout from '../routes/checkout.jsx'
import CheckoutPre from '../routes/checkout-pre.jsx'
import CheckoutSuccess from '../routes/checkout-success.jsx'
import Billing from '../components/dashboard/Billing.jsx'
import DashboardParent from '../routes/dashboard/parent.jsx'
import DashboardMain from '../routes/dashboard/dashboard-main.jsx'
import DashboardCheck from '../routes/dashboard/dashboard-check.jsx'
import API from '../components/dashboard/Api.jsx'
import Event from '../components/dashboard/Event.jsx'
import CreateEvent from '../routes/create-event.jsx'
import PublicEvent from '../routes/public-event.jsx'
import Archive from '../components/dashboard/Archive.jsx'

/* eslint-disable react-hooks/rules-of-hooks */

const router = createBrowserRouter([
  {
    path: '/code',
    element: <Code />,
  },
  {
    path: '/',
    element: <DashboardCheck />,
    loader: useMiddleware(requireAuth),
  },
  {
    path: '/playground',
    element: <Playground />,
  },
  {
    path: '/dashboard',
    element: <DashboardCheck />,
  },
  {
    path: '/dashboard/:teamId',
    element: <DashboardParent />,
    loader: useMiddleware(requireAuth),
    children: [
      {
        path: '',
        element: <DashboardMain />,
      },
      {
        path: 'event/:eventId',
        element: <Event />,
      },
      {
        path: 'archive',
        element: <Archive />,
      },
      {
        path: 'team',
        element: <Team />,
      },
      {
        path: 'api',
        element: <API />,
      },
      {
        path: 'billing',
        element: <Billing />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
  {
    path: '/verify',
    element: <Verify />,
    loader: useMiddleware(requireAuth),
  },
  {
    path: '/event/:eventId',
    element: <PublicEvent />,
  },
  {
    path: '/rundown/:rundownId',
    element: <RundownParent />,
    loader: useMiddleware(awaitUser),
    children: [
      {
        path: '',
        element: <RundownMain />,
      },
      {
        path: 'output',
        element: <RundownOutput />,
        // children: [
        //   {
        //     path: 'amplified',
        //     element: <RundownOutputAmplified />,
        //   },
        //   {
        //     path: 'stage',
        //     element: <RundownOutputStageMonitor />,
        //   },
        // ],
      },
    ],
  },
  {
    path: '/checkout',
    element: <Checkout />,
    loader: useMiddleware(requireAuth),
  },
  {
    path: '/checkout-pre',
    element: <CheckoutPre />,
    loader: useMiddleware(requireAuth),
  },
  {
    path: '/checkout-success',
    element: <CheckoutSuccess />,
    loader: useMiddleware(requireAuth),
  },
  {
    path: '/accept-invite',
    element: <AcceptInvite />,
    loader: useMiddleware(requireAuth),
  },
  {
    path: '/create',
    element: <Create />,
    loader: useMiddleware(awaitUser),
  },
  {
    path: '/create-event',
    element: <CreateEvent />,
    loader: useMiddleware(awaitUser),
  },
  {
    path: '/login',
    element: <Login />,
    loader: useMiddleware(dashboardRedirect),
  },
  {
    path: '/logout',
    element: <Logout />,
  },
  {
    path: '/reset',
    element: <Reset />,
  },
  {
    path: '/signup',
    element: <Signup />,
    loader: useMiddleware(dashboardRedirect),
  },
])

export default function Router() {
  const addToast = useSetAtom(toastAtom)

  injectAddToast(addToast)

  return (
    <>
      <ToastWrapper />
      <RouterProvider router={router} />
    </>
  )
}

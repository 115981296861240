import { faSearch, faCircleXmark, faArrowDownShortWide } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useMemo } from 'react'
import { Link, useOutletContext, useParams } from 'react-router-dom'
import DashboardWrapper from '../../components/dashboard/DashboardWrapper'
import { ALPHABETICAL, CREATED_NEW_OLD, CREATED_OLD_NEW, RUNDOWN_ARCHIVED_AT, RUNDOWN_START_TIME } from '../../constants/sortTypes.js'
import RundownCard from '../../components/dashboard/partials/RundownCard.jsx'
import EventCard from '../../components/dashboard/partials/EventCard.jsx'
import Fuse from 'fuse.js'
import { Menu, MenuItem } from '../interactives/DropdownMenu.jsx'

export default function Archive () {
  const {
    rundownList,
    eventList,
  } = useOutletContext()
  const { teamId } = useParams()
  const [searchText, setSearchText] = useState('')
  const [sortedBy, setSortedBy] = useState(RUNDOWN_ARCHIVED_AT)

  const sortItems = (items, sortBy) => {
    return items.slice().sort((a, b) => {
      switch (sortBy) {
        case RUNDOWN_ARCHIVED_AT:
          return new Date(a.archivedAt) < new Date(b.archivedAt) ? 1 : -1
        case RUNDOWN_START_TIME:
          return new Date(a.startTime) < new Date(b.startTime) ? 1 : -1
        case CREATED_NEW_OLD:
          return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
        case CREATED_OLD_NEW:
          return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
        default:
          const _A = a.name.toUpperCase()
          const _B = b.name.toUpperCase()
          return (_A < _B) ? -1 : (_A > _B) ? 1 : 0
      }
    })
  }

  const sortedEvents = useMemo(() => sortItems(eventList.filter((e) => e.archivedAt), sortedBy), [eventList, sortedBy])
  const sortedRundowns = useMemo(() => sortItems(rundownList.filter((r) => r.archivedAt), sortedBy), [rundownList, sortedBy])

  const filterItems = (items, searchText) => {
    const fuse = new Fuse(items, { keys: ['name'] })
    return searchText ? fuse.search(searchText).map(result => result.item) : items
  }

  const filteredEvents = useMemo(() => filterItems(sortedEvents, searchText), [searchText, sortedEvents])
  const filteredRundowns = useMemo(() => {
    const activeEventIds = eventList.filter(e => !e.archivedAt).map(e => e.id)
    return filterItems(sortedRundowns, searchText).filter(r => !r.eventId || activeEventIds.includes(r.eventId))
  }, [searchText, eventList, sortedRundowns])

  // if (!team) return <DashboardCheck />

  return (
    <DashboardWrapper
      data-label="layout-dashboard"
      title={
        <div className='flex flex-col sm:flex-row gap-2 items-center sm:items-start'>
          <Link to={`/dashboard/${teamId}/`} className='text-gray-400 hover:text-gray-100 transition-colors'>Dashboard</Link>
          <span className='text-gray-400 hidden sm:inline-block'>→</span>
          <p>Archive</p>
        </div>
      }
    >
      <>
        <div className='mt-4 flex flex-col sm:flex-row gap-2 justify-between'>
          <span className='flex-1'></span>
          <div className='pr-3 bg-gray-100/5 focus-within:bg-gray-100/10 focus-within:ring rounded w-full sm:w-min whitespace-nowrap flex items-center justify-between'>
            <input
              placeholder='Find event or rundown'
              className='px-3 h-8 bg-transparent focus:outline-none'
              value={searchText}
              onChange={(e) => { setSearchText(e.target.value) }}
            />
            {searchText ?
              <button className='h-7 focus:outline-none focus:ring rounded' onClick={()=>setSearchText('')}><FontAwesomeIcon icon={faCircleXmark} /></button>
              :
              <FontAwesomeIcon icon={faSearch} />
            }
          </div>

          <Menu label="Sort" icon={faArrowDownShortWide}>
            <MenuItem
              label="Archived time"
              onClick={() => setSortedBy(RUNDOWN_ARCHIVED_AT)}
              active={sortedBy === RUNDOWN_ARCHIVED_AT}
            />
            <MenuItem
              label="Rundown start time"
              onClick={() => setSortedBy(RUNDOWN_START_TIME)}
              active={sortedBy === RUNDOWN_START_TIME}
            />
            <MenuItem
              label="Created (latest)"
              onClick={() => setSortedBy(CREATED_NEW_OLD)}
              active={sortedBy === CREATED_NEW_OLD}
            />
            <MenuItem
              label="Created (oldest)"
              onClick={() => setSortedBy(CREATED_OLD_NEW)}
              active={sortedBy === CREATED_OLD_NEW}
            />
            <MenuItem
              label="Alphabetical"
              onClick={() => setSortedBy(ALPHABETICAL)}
              active={sortedBy === ALPHABETICAL}
            />
          </Menu>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {/* EVENTS */}
          <div className='flex col-span-full items-center gap-2'>
            <div className='w-4 bg-gray-800 h-0.5'></div>
            <p className='text-xs text-gray-600'>Archived events</p>
            <div className='grow bg-gray-800 h-0.5'></div>
          </div>

          {filteredEvents.length === 0 ? (
            <div className='h-[8rem] border border-dashed border-gray-800 text-gray-500 rounded flex flex-col justify-center text-center'>
              No archived events
            </div>
          ) : filteredEvents.map((event)=> (
            <EventCard
              key={event.id}
              event={event}
              rundowns={rundownList.filter((rundown) => rundown.eventId === event.id)}
            />
          ))}

          <div className='flex col-span-full items-center gap-2'>
            <div className='w-4 bg-gray-800 h-0.5'></div>
            <p className='text-xs text-gray-600'>Archived rundowns</p>
            <div className='grow bg-gray-800 h-0.5'></div>
          </div>

          {/* RUNDOWNS */}
          {filteredRundowns.length === 0 ? (
            <div className='h-[8rem] border border-dashed border-gray-800 text-gray-500 rounded flex flex-col justify-center text-center'>
              No archived rundowns
            </div>
          ) : filteredRundowns.map((rundown) => (
            <RundownCard
              key={rundown.id}
              rundown={rundown}
              eventList={eventList}
            />
          ))}
        </div>

      </>
    </DashboardWrapper>
  )
}

import { useState } from 'react'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import Layout from '../components/Layout'
import Input from '../components/Input'
import Button from '../components/Button'
import { useSetAtom } from 'jotai'
import { toastAtom } from '../store/toast.store.js'

export default function Reset() {
  const [email, setEmail] = useState('')
  const [sendingReset, setSendingReset] = useState(false)
  const [resetSent, setResetSent] = useState(false)

  const addToast = useSetAtom(toastAtom)

  const handleSendResetEmail = async (e) => {
    e.preventDefault()

    if (!email) return null

    setSendingReset(true)

    const auth = getAuth()

    await sendPasswordResetEmail(auth, email)
      .then(() => {
        // Sent
        setSendingReset(false)
        setResetSent(true)
      })
      .catch((error) => {
        const errorCode = error.code
        setSendingReset(false)
        switch (errorCode) {
          case 'auth/user-not-found':
            addToast({ title: 'We couldn\'t find your account', type: 'fail' })
            break
          case 'auth/wrong-password':
            addToast({ title: 'Your email and password did not match', type: 'fail' })
            break

          default:
            addToast({ title: 'An error occured', body: 'Please try again', type: 'fail' })
            break
        }
      })
  }
  return (
    <Layout title="Reset password">
      {resetSent ?
        <p className='text-center'>Reset email sent! Please check your inbox.</p>
        :
        <form onSubmit={(e) => handleSendResetEmail(e)} className="flex flex-col gap-4">
          <Input label="Email" type="email" placeholder="me@email.com" autofocus={true} onChange={(change) => setEmail(change)} value={email}/>
          <Button
            disabled={!email || sendingReset}
            loading={sendingReset}
            text={<span>{sendingReset ? 'Sending reset email...' : 'Send reset email'}</span>}
            onClick={() => {}}
            type='submit'
          />
        </form>
      }
    </Layout>
  )
}

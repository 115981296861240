import { CUE_STATE_ACTIVE, CUE_STATE_PAST } from '../../constants/cueStates.js'
import PropTypes from 'prop-types'
import { floorSeconds, formatCountdown, formatDurationHuman } from '../../utils/formatTime.js'
import EditableInput from '../EditableInput.jsx'
import EditableDurationWrapper from './EditableDurationWrapper.jsx'

export default function CueItemHeader ({
  cue,
  onUpdateCue,
  moment,
  timestamp,
  readonly = false,
}) {
  const endDelta = timestamp ? floorSeconds(timestamp.elapsed) : 0
  const endDeltaOver = endDelta > timestamp?.duration

  return (
    <div className="relative h-full flex group">
      <div className="w-[7rem] flex-none pl-1 flex flex-col justify-center text-right">
        <div className="flex-1">
          {/* Spacer */}
        </div>
        <div className="h-7 leading-7">
          {timestamp?.state === CUE_STATE_ACTIVE ? (
            <CueItemElapsed
              className="text-lg font-[500] font-mono"
              cue={cue}
              moment={moment}
            />
          ) : (
            <EditableDurationWrapper
              duration={cue.duration}
              updateDuration={(duration) => onUpdateCue({ duration })}
              disabled={readonly || cue.locked}
              className={[
                timestamp?.state === CUE_STATE_PAST ? 'line-through opacity-50' : '',
              ].join(' ')}
            />
          )}
          {timestamp?.state === CUE_STATE_PAST ? (
            <div className={[
              'mr-2 -mt-2',
              endDeltaOver ? 'text-red-500' : 'text-green-500',
            ].join(' ')}>
              {formatDurationHuman(endDelta)}
            </div>
          ) : ''}
        </div>
        <div className="flex-1">
          {timestamp?.state === CUE_STATE_ACTIVE ? (
            <CueItemDuration
              className="text-sm text-red-300 font-[400]"
              duration={cue.duration}
            />
          ) : ''}
        </div>
      </div>
      <div className="flex-grow px-4 py-2 flex flex-col justify-center">
        <div className="flex-1">
          {/* Spacer */}
        </div>
        <div className="flex-0 leading-6">
          <EditableInput
            className="w-full"
            value={cue.title}
            placeholder={readonly ? '' : 'Title'}
            onChange={(title) => onUpdateCue({ title })}
            disabled={readonly || cue.locked}
          />
        </div>
        <div className="flex-1">
          <CueItemSubtitle
            className="text-sm text-white/60"
            text={cue.subtitle}
            updateText={(subtitle) => onUpdateCue({ subtitle })}
            disabled={readonly || cue.locked}
            readonly={readonly}
          />
        </div>
      </div>
    </div>
  )
}

CueItemHeader.propTypes = {
  cue: PropTypes.object.isRequired,
  onUpdateCue: PropTypes.func.isRequired,
  moment: PropTypes.object,
  timestamp: PropTypes.object, // undefined for type='headings'
  readonly: PropTypes.bool,
}

export function CueItemElapsed ({ className, overtimeClassName, moment }) {
  const overtime = moment.left <= 0
  return (
    <div
      className={[
        'px-2',
        (overtime ? 'bg-gray-900 rounded text-red-500' : 'text-white'),
        (overtime && overtimeClassName),
        className,
      ].join(' ')}
    >
      {overtime && '+'}
      {formatCountdown(moment.countdown)}
    </div>
  )
}

CueItemElapsed.propTypes = {
  className: PropTypes.string,
  overtimeClassName: PropTypes.string,
  moment: PropTypes.object.isRequired,
}

function CueItemDuration ({ className, duration = 0 }) {
  return (
    <div className={['px-2', className].join(' ')}>
      {formatDurationHuman(duration)}
    </div>
  )
}

CueItemDuration.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number, // Duration in ms
}

function CueItemSubtitle ({
  className,
  text = '',
  updateText,
  disabled = false,
  readonly = false,
}) {
  return (
    <div className={[className, 'text-left'].join(' ')}>
      <EditableInput
        value={text}
        placeholder={readonly ? '' : 'Subtitle'}
        onChange={updateText}
        disabled={disabled}
      />
    </div>
  )
}

CueItemSubtitle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  updateText: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
}

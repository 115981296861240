// Images
export const PNG = 'image/png'
export const JPG = 'image/jpeg'
export const GIF = 'image/gif'
export const SVG = 'image/svg+xml'
export const TIFF = 'image/tiff'
export const WEBP = 'image/webp'

// Media
export const MIDI = 'audio/midi'
export const MP3 = 'audio/mpeg'
export const MP4 = 'video/mp4'
export const MPEG = 'video/mpeg'
export const WAV = 'audio/wav'
export const WEBA = 'audio/webm'
export const WEBM = 'video/webm'

// Documents
export const PDF = 'application/pdf'
export const CSV = 'text/csv'
export const DOC = 'application/msword'
export const DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const XLS = 'application/vnd.ms-excel'
export const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const PPT = 'application/vnd.ms-powerpoint'
export const PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
export const ODT = 'application/vnd.oasis.opendocument.text'
export const ODS = 'application/vnd.oasis.opendocument.spreadsheet'
export const ODP = 'application/vnd.oasis.opendocument.presentation'

// Other
export const ICS = 'text/calendar'
export const ZIP = 'application/zip'
export const ZIP7 = 'application/x-7z-compressed'
export const RAR = 'application/vnd.rar'
export const GZIP = 'application/gzip'
export const TAR = 'application/x-tar'

// Misc
export const MAX_FREE_FILE_SIZE = 5 * 1024 * 1024 //    5 MB
export const MAX_FILE_SIZE = 1 * 1024 * 1024 * 1024 //  1 GB

export const IMAGE_TYPES = [PNG, JPG, GIF, SVG, TIFF, WEBP]
export const MEDIA_TYPES = [MIDI, MP3, MP4, MPEG, WAV, WEBA, WEBM]
export const DOCUMENT_TYPES = [PDF, CSV, DOC, DOCX, XLS, XLSX, PPT, PPTX, ODT, ODS, ODP]
export const OTHER_TYPES = [ICS, ZIP, ZIP7, RAR, GZIP, TAR]

import Input from '../Input'
import DashboardWrapper from './DashboardWrapper'
import { updateProfile } from 'firebase/auth'
import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'

export default function Profile() {
  const { user } = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [newName, setNewName] = useState(user.displayName)

  const handleUpdateProfile = async (update) => {
    await updateProfile(user, update)
  }

  return (
    <DashboardWrapper title='Profile'>

      <div className="grid grid-cols-2">
        <Input
          label="Email"
          value={user?.email}
          placeholder="me@email.com"
          loading={!user}
          disabled={true}

        />
      </div>

      <div className="mt-4 grid grid-cols-2">
        <Input
          label="Name"
          value={newName}
          onChange={setNewName}
          placeholder="My name"
          loading={!user || loading}
          onBlur={async (e) => {
            if(!e.target.value) return
            setLoading(true)
            await handleUpdateProfile({displayName: newName})
            setLoading(false)
          }}
        />
      </div>

    </DashboardWrapper>
  )
}

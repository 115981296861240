import { useEffect } from 'react'
import Layout from '../components/Layout'
import { useSearchParams } from 'react-router-dom'
import PaddleLoader from '../utils/paddleLoader'
import { getUser } from '../firebase'

export default function Checkout() {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const planId = searchParams.get('plan_id')
    const teamId = searchParams.get('team_id')
    const discountCode = searchParams.get('discount_code')
    const discountId = searchParams.get('discount_id')
    const additionalMembersId = searchParams.get('additional_members_id')
    const additionalMembersCount = searchParams.get('additional_members_count')
    if (planId && teamId) initCheckout({planId, teamId, discountCode, discountId, additionalMembersId, additionalMembersCount })

    // Load a specific transaction
    const transactionId = searchParams.get('_ptxn')
    if (transactionId) initTransactionCheckout({ transactionId })
  }, [])

  async function initCheckout ({planId, teamId, discountCode, discountId, additionalMembersId, additionalMembersCount } = {}) {
    const paddle = await PaddleLoader.getInstance()
    const user = await getUser()
    const query = new URLSearchParams({ 'plan_id': planId, 'team_id': teamId })

    let items = [
      {
        'quantity': 1,
        'price_id': planId,
      },
    ]

    if (additionalMembersId) {
      console.log(additionalMembersId, additionalMembersCount)
      items.push(
        {
          'quantity': parseInt(additionalMembersCount),
          'price_id': additionalMembersId,
        },
      )
    }

    const checkoutConfig = {
      settings: {
        successUrl: `${window.location.origin}/checkout-success?${query}`,
      },
      items,
      customData: {
        uid: user.uid,
        teamId: teamId,
      },
      customer: {
        email: user.email,
      },
    }
    if (discountCode) checkoutConfig.discountCode = discountCode
    else if (discountId) checkoutConfig.discountId = discountId

    // Trigger Paddle checkout
    paddle.Checkout.open(checkoutConfig)
  }

  // Load a specific transaction
  async function initTransactionCheckout ({ transactionId } = {}) {
    const paddle = await PaddleLoader.getInstance()
    return paddle.Checkout.open({ transactionId })
  }

  return (
    <Layout title="Checkout">
    </Layout>
  )
}
